.CustomAccordionContainer {
  width: 100%;

  .Trigger {
    height: 2.4rem;
    padding: 0.4rem 0.625rem;
    border-radius: 10px;
    background-color: #f2f5f9;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;

    img {
      cursor: pointer;
    }

    .Count {
      display: inline-block;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      background-color: #200865;
      margin-left: 0.75rem;
      bottom: 7;
      color: #ffffff;
      text-align: center;
    }
  }
}
