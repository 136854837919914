    .contact-row{
        display: flex;
        min-height: 80vh;
        overflow: hidden;
        border-radius:6px;
        box-shadow: 0 1rem 2rem rgba(0,0,0,.2);
    }

    .contact-row h2{
        color: white;
        text-align: start;
    }

    .contact-row p{
        color: white;
        text-align: start;
    }

    .contact-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .column--1{
        display: flex;
        background-color: #0074d9;
        color: white;
    }

    .column--2{
        display: flex;
        flex-direction: column;
        background-color: white;
        width:70%;
    }

    .contact__form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        height: 100%;
        width: 90%;
        margin:0 auto 80px;
    }

    .contact-details {
        width: 50%;
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        align-self: center;
        position: relative;
    }


    .contact-details i {
        position: absolute;
        top: 10px;
        left: -55px;
        font-size: 20px;
    }

    .contact-social{
        width: 50%;
        align-self: center;
    }

    .contact-social i {
        color: white;
        font-size: 30px;
        margin-right: 15px;
    }





    .form-header{
        font-size: 2rem;
        margin-top: 40px;
        margin-bottom: 60px;
        color: #0074d9;
        text-align: start;
    }

    .form__group{
        width: 100%;
        margin-bottom: 45px;
    }

    .input__length{
        width: 45%;
        border: none;
        border-bottom: 1px solid black;
        margin-right: 30px;
        margin-bottom: 25px;
        margin-top: 30px;
    }

    .contact-button {
        background: #0074d9;
        border: none;
        color: white;
        margin-top: 35px;
        width: 8rem;
        height: 2.5rem;
        border-radius: 1.5rem;
        cursor: pointer;
        align-self: start;
    }

    .text-area{
        height: 60px;
        border: none;
        border-bottom: 1px solid black;
    }

    @media only screen and (max-width: 1500px){

    }

    @media only screen and (max-width: 1366px){

    }

    @media only screen and (max-width: 1200px){

    }

    @media only screen and (max-width: 1024px){
        .input__length {
            width: 44%;
        }
    }

    @media only screen and (max-width: 992px){
        .input__length {
            width: 44%;
        }
    }

    @media only screen and (max-width: 768px){
        .contact-social i {
            font-size: 20px;
        }

        .input__length {
            width:100%;
           /* margin-top: 20px;*/
        }
    }

    @media only screen and (max-width: 480px){
        .contact-row{
            flex-direction: column;
            margin-top: 50px;
        }

        .contact-details  {
            font-size: 15px;
            width: 75%;
            align-self: flex-end;
        }

        .contact-social{
            margin-bottom: 30px;
        }

        .contact-social i {
            font-size: 20px;
        }

        .contact-info{
            margin-top: 40px;
        }

        h2{
            font-size: 15px;
        }

        .column--2 {
            width: 100%;
        }

        .contact-form{
            margin: 0 auto 20px;
        }

        .form-header{
            margin-bottom: 30px;
        }

        .form__group {
            margin-bottom: 20px;
        }

        .input__length {
            width:100%;
            margin-top: 20px;
        }
    }

