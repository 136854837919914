.AggregatorStats {
  .AggregatorCurrencyContainer {
    display: flex;
    justify-content: flex-end;
    padding-right: 0.7rem;
    gap: 5px;

    .AggregatorProduct {
      width: 10rem;

      @media screen and (max-width: 712px) {
        width: 100%;
      }
    }

    .AggregatorCurrency {
      width: 10rem;

      @media screen and (max-width: 712px) {
        width: 100%;
      }
    }
  }
}
