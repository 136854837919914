.Header {
  margin-bottom: 3rem;
}

.investmentLayoutCard {
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #d7dce0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 2rem;
  padding: 1.5rem;
  height: 10rem;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 8px;
    color: #24292e;
  }
  .content {
    padding-bottom: 1.5rem;
    color: #677684;
  }
  .link {
    padding-top: 1.5rem;
    color: #713fff;
  }
  .link a:hover {
    color: rgb(0, 0, 0);
    text-decoration: underline;
  }

  .percent {
    color: #713fff;
  }
}
.investmentLayoutCard:hover {
  border: 1px solid #713fff;
}

.ActionContainer {
  display: flex;
  justify-content: space-between;

  .FilterOptions {
    display: flex;

    .FormGroup {
      position: relative;

      .SearchInput {
        border: 1px solid #d7dce0;
        width: 18rem;
        height: 2.6rem;
        border-radius: 4px;
        padding: 0 1rem;
        color: #677684;
        margin-right: 1rem;
      }

      .Icon {
        position: absolute;
        right: 2rem;
        top: 0.8rem;
        color: #677684;
      }
    }

    .FormButtonContainer {
      .SearchActionButton {
        height: 2.6rem;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .ActionContainer {
    flex-direction: column;

    .FilterOptions {
      display: flex;
      justify-content: space-between;

      .FormButtonContainer {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media only screen and (max-width: 630px) {
  .ActionContainer {
    .FilterOptions {
      display: flex;
      flex-direction: column;

      .FormGroup {
        .SearchInput {
          width: 100%;
        }

        .Icon {
          position: absolute;
          right: 2rem;
          top: 0.8rem;
          color: #677684;
        }
      }
    }
  }
}
