.cards_container {
  margin-top: 30px;

  .cards_header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .user_icon {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}
