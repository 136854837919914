.Tabs {
  width: 100%;
  height: auto;
  overflow-x: auto;
}

.Tabs .tabs__wrapper {
  /* padding: 1em 0; */
  margin: 0 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  cursor: pointer;
  display: flex;
  width: max-content;
}

.Tabs .tabs__wrapper .filterItem {
  padding: 1em;
  color: #0a1532;
  margin-left: 1rem;
}

.Tabs .tabs__wrapper .filterItem i {
  margin-right: 0.4rem;
}

.Tabs .tabs__wrapper:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05) !important;
}

.Tabs .round {
  border-radius: 50%;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.07);
  /* background-color: #0A1532; */
  /* width: 35px;
    height: 35px;
    line-height: 35px; */
  color: #0a1532;
  font-weight: bold;
  text-align: center;
  padding: 5px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .Tabs .tabs__wrapper .filterItem {
    text-align: center !important;
    font-size: 11px !important;
    margin: 0 !important;
    padding: 0.8em;
  }

  .Tabs .tabs__wrapper .filterItem i {
    display: block !important;
    margin-bottom: 0.4rem;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .Tabs .tabs__wrapper .filterItem {
    margin: 0 !important;
    text-align: center !important;
  }

  .Tabs .tabs__wrapper .filterItem i {
    margin-right: 0.4rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .Tabs .tabs__wrapper .filterItem {
    margin: 0 !important;
  }

  .Tabs .tabs__wrapper .filterItem i {
    margin-right: 0.4rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .Tabs .tabs__wrapper .filterItem i {
    margin-right: 0.4rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
