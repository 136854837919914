.BreadCrumbs {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
}

.BreadCrumbs .breadcrumb__location {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -7px;
  border-left: 1px solid rgba(0, 0, 0, 0.14);
  padding: 6px;
}

.BreadCrumbs .rounder {
  position: relative;
  display: inline;
  float: left;
  margin: -7px 0 0 0;
}

.BreadCrumbs .breadcrumb__location__title {
  display: inline;
  color: inherit;
  transition: all 0.4s;
}

.BreadCrumbs .breadcrumb__location__title:hover {
  color: #713fff !important;
  cursor: pointer;
}

.BreadCrumbs .breadcrumb__location__icon {
  margin: auto 6px;
  color: rgba(0, 0, 0, 0.14);
}
