.TableResponsive,
.Table {
  border: none !important;

  th,
  td {
    text-align: left;
  }

  th {
    font-size: 0.875rem;
  }

  .TableCell {
    text-align: left !important;
    border-bottom: 1px solid #bac2c9;
  }

  .ViewDetails {
    color: var(--colorBlue);
    cursor: pointer;
  }
}
