.accordion-content {
  max-width: 45.625rem;
  background-color: #f9f9f9; /* Example accordion content background color */
  padding: 20px;
  margin-top: 5px;
  h6 {
    background-color: #f8f8f8;
    color: #150849;
    font-weight: 400;
    font-size: 14px;
  }
  ul {
    list-style-image: url("../../../../../../../src//assets/images/tick-circle.png");
    li div small {
      color: #150849;
      font-size: 14px;
    }
  }
}
