.container {
    width: 100%;
    display: flex;
    justify-content: center;

    .formContainer {
        width: 640px;

        .form {
            display: flex;
            justify-content: center;
            flex-direction: column;

            .textBox {
                margin: 20px 0;
            }
        }
    }
}