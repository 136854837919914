.modal_title {
    color: #200865;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 20px;
    margin-bottom: 20px;
}

.table {
    width: 100%;
    border-collapse: collapse;

    th, td {
        border: 1px solid rgb(218, 218, 218);
        padding: 8px;
        text-align: left;
    }
    th {
        color:#170D33;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        font-size: 14px;
    }
    td {
        color: #645E77;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        font-size: 14px;

        &:last-of-type{
            text-align: center;

            .icon_button {
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }

        &:nth-of-type(3) {
            text-align: center;

            .pendingStatus {
                border-radius: 40px;
                color: #F5A623;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                background: #FEF4E5;
                padding: 2px 10px;
            }
        }
    }

    .cam_button {
        position: relative;

        ._btn {
            position: absolute;
            top: -90px;
            right: -10px;
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            width: 153px;
            // height: 116px;
            background-color: #fff;
            padding: 8px;
            border-radius: 10px;
            box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.20);

            button {
                display: inline-block;
                width: 100%;
                border: none;
                background-color: transparent;
                color: #001540;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
                padding: 5px 0;

                &:hover {
                    background-color: rgb(246, 246, 246);
                }
            }
        }
    }
}

.DownloadIcon {
    margin-left: 1rem;
}
