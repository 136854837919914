.tierListItem {
  display: flex;

  .iconContainer {
    display: inline-block;
    border: 1px solid var(--colorDarkBlue);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    font-size: 0.75rem;
    padding-top: 0.1rem;
  }

  .pending {
    border: 1px solid #8b8699;

    i {
      color: #8b8699;
    }
  }

  .itemTitle {
    padding-left: 1rem;
    margin-top: 0.2rem;
  }

  .greyedOutTitle {
    color: #8b8699;
  }
}
