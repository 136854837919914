.alignGraphLabels {
  display: flex;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 24px;

  span {
    margin-right: 8px;
    &::before {
      display: inline-block;
      content: " ";
      height: 12px;
      width: 12px;
      margin-right: 4px;
      border-radius: 2px;
    }

    &.bgInActive {
      &::before {
        background-color: #200865;
      }
    }
    &.bgActive {
      &::before {
        background-color: #ff5e2e;
      }
    }
  }
}

@mixin tags {
  padding: 4px 8px;
  border-radius: 0.25rem;
  text-transform: capitalize;
}

@mixin activeTag {
  @include tags();
  background-color: #e9fbf1;
  color: #27be63;
}
@mixin inactiveTag {
  @include tags();
  color: #e6a900;
  background-color: #fff9e5;
}
@mixin successTag {
  @include tags();
  color: var(--colorDimGreen);
  background-color: #eafbf1;
}
@mixin pendingTag {
  @include tags();
  color: #5e4bce;
  background-color: #edebfa;
}
@mixin errorTag {
  @include tags();
  color: var(--colorDimRed);
  background-color: #fce9e9;
}
@mixin rejectedTag {
  @include tags();
  color: var(--colorDimRed);
  background-color: #fce9e9;
}

.dashboard-graph-list {
  padding: 0px;
  list-style: none;

  li {
    display: flex;
    padding: 8px 0px;
    margin-bottom: 4px;
    justify-content: space-between;
    flex-wrap: wrap;
    line-height: 16px;
    font-size: 14px;
    border-bottom: 1.5px dashed var(--colorMidGrey);

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:nth-child(1) {
        max-width: 65%;
      }
      &:nth-child(2) {
        max-width: 35%;
        &.active {
          @include activeTag();
        }
        &.inactive {
          @include inactiveTag();
        }
        &.success {
          @include successTag();
        }
        &.repaid {
          @include successTag();
        }
        &.pending {
          @include pendingTag();
        }
        &.error {
          @include errorTag();
        }
        &.rejected {
          @include rejectedTag();
        }
        &.failed {
          @include errorTag();
        }
        &.due {
          @include errorTag();
        }
        &.overdue {
          @include errorTag();
        }
      }
    }
    .dashbord-graph-list-name {
      color: var(--colorLightGrey);
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1rem; /* 114.286% */
      letter-spacing: 0.025rem;
    }
    .dashboard-graph-list-amount {
      color: var(--colorDarkPurple);
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1rem; /* 114.286% */
      letter-spacing: 0.00175rem;
    }
  }
}
