.FeedbackCommentForm {
  .Title {
    font-weight: 800;
  }
  .UploadCreditScore {
    width: 100%;
    margin-top: 2.5rem;
    display: flex;
    gap: 1rem;

    .InputContainer {
      width: 100%;

      label {
        color: #8b8699;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
      }

      .CustomInput {
        height: 3.125rem;
        border-radius: 0.625rem;
        border: 0.5px solid #d1cfd6;

        .Text {
          padding: 0.9375rem;
          text-decoration: underline;
          cursor: pointer;
          margin-left: 1rem;
          color: #713fff;

          &::before {
            content: url("../../../../../assets/svg/icons/document-upload.svg");
            position: relative;
            top: 0.2rem;
            left: -0.5rem;
          }
        }
      }
    }

    .ActionButton {
      padding-top: 2rem;

      button {
        height: 3.125rem;
      }
    }
  }

  .UploadedFile {
    font-style: italic;
    display: block;
  }

  .CreditDecision {
    height: 7.625rem;
    border-radius: 0.625rem;
    border: 0.5px solid #d1cfd6;
    margin-bottom: 3rem;
    padding-left: 1.75rem;
    padding-top: 1rem;

    .Action {
      display: flex;
      gap: 2rem;

      .Yes,
      .No {
        margin: 0;
        height: 3rem;
        width: 4.6875rem;
        border: none;
        border-radius: 0.625rem;
        border: 1px solid #d1cfd6;
        background: #fff;
      }

      .Active {
        background: #713fff;
        color: white;
        border: none;
      }
    }
  }
}
