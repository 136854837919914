.Login input.error {
  border-bottom: 1px solid crimson !important;
  color: crimson;
}

/* .sidebar-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

.header-title {
  font-size: 6rem;
  font-weight: 700;
  margin-bottom: 40px;
  font-stretch: extra-expanded;
}

.header-text {
  font-size: 2rem;
  color: #713fff;
}

.login-header-margin {
  margin-top: -5px;
}

.side-login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  width: 100%;
}
.form .form-group--1 {
  width: 100%;
}
/*  
.form-group--1 {
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}

.form-group--2 {
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}

.form-group--3 {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  text-align: left;
}

.form-group--3 span {
  text-align: left;
}

.form-group--1 i {
  font-size: 20px;
  color: black;
  position: absolute;
  top: 15px;
  right: 0;
}

.form-group--2 i {
  font-size: 20px;
  color: black;
  position: absolute;
  top: 15px;
  right: 0;
} */
*/

/* .form-group__input {
  font-size: 1rem;
  font-family: inherit;
  color: white;
  padding: 0.8rem 1rem 0.8rem 0;
  background-color: #713fff;
  border: none;
  border-bottom: 2px solid white;
  width: 100%;
  display: block;
  transition: all 0.3s;
} */

.form-group__input:focus {
  background: #713fff;
  border-bottom: 2px solid #713fff;
}

.form-group__input:focus ~ i {
  color: white !important;
}

.form-group__input::placeholder {
  color: white;
  padding: 0;
}

.btn-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.reset p {
  color: #000;
  font-weight: 400;
}

.reset a {
  font-weight: 600;
}

.login-button {
  background: #713fff;
  border: none;
  width: 93px;
  height: 48px;
  border-radius: 4px;
  cursor: pointer;
  color: #ffff;
}

.login-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.login-button:active {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.text-block {
  float: left;
}

.text-block__header {
  color: black;
  font-size: 2.5rem;
  font-weight: 400;
  margin-top: -20px;
  margin-bottom: 15px;
  text-align: left;
}

.text-block__cta {
  text-align: left;
  font-size: 15px;
  color: black;
}

.loader-margin {
  margin-bottom: 30px;
}

.form-group--3 #email-msg,
.form-group--3 #password-msg {
  margin-left: 0 !important;
  text-align: left;
  font-size: 12px;
  width: 100%;
  display: block;
}

@media (max-width: 1381px) {
  .text-block {
  }
}

@media (max-width: 1251px) {
  .text-block {
  }
}

@media (max-width: 1150px) {
  .text-block {
  }
}

@media (max-width: 1000px) {
  .text-block {
  }
}

@media (max-width: 768px) {
  .text-block {
    margin: 0 auto;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .text-block {
    margin: 0 auto;
  }
  .text-block__header {
    font-size: 1.8rem;
    text-align: center;
  }
}

@media (max-width: 320px) {
  .text-block {
    margin: 0 auto;
  }
}

@media (min-width: 1281px) {
  .form {
    width: 100%;
  }
  .side-login-padding {
    padding: 50px;
  }
}

@media (max-width: 1110px) {
  .btn-section {
    flex-direction: column;
  }
}

@media (max-width: 985px) {
  .btn-section {
    flex-direction: column;
  }
}

@media (max-width: 769px) {
  .btn-section {
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .btn-section {
    flex-direction: column;
  }
}

@media (max-width: 481px) {
  .btn-section {
    flex-direction: column;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .form {
    width: 100%;
  }
  .side-login-padding {
    padding: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .form {
    width: 100%;
  }
  .side-login-padding {
    padding: 30px;
  }
}

@media (max-width: 1110px) {
  .login-button {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .side-login-padding {
    margin-top: 56px;
  }
  .reset,
  .login {
    width: 100%;
  }
  .text-block__header,
  .text-block__cta {
    text-align: center;
  }
  .tfx-header .tfx-header__top {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .form {
    width: 100%;
  }
  .side-login-padding {
    padding: 15px;
  }
  .sidebar-heading {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .btn-form {
    margin: auto;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .form {
    margin-top: 15px;
    width: 90%;
  }
  .side-login-padding {
    padding: 15px;
  }
  .sidebar-heading {
    display: none;
  }
}

@media (max-width: 320px) {
  .form {
    margin-top: 25px;
    width: 90%;
  }
  .side-login-padding {
    padding: 15px;
  }
  .sidebar-heading {
    display: none;
  }
  .text-block__header {
    font-size: 2rem;
    margin-bottom: 10px;
  }
}
