.investmentTitle {
  color: #677684;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
}
.investmentSubTitleTitle {
  color: #24292e;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
}
.investmentSubTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #0d0f11;
}
.subTitle {
  color: #713fff;
}

.portfolio {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #0d0f11;
  margin-top: 40px;
}
.rightBorder {
  border-right: 1px solid #d7dce0;
  padding-right: 70px;
}
