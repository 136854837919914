.noDataWrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;

  svg {
    height: 80px;
    width: 80px;
    padding-bottom: 20px;
  }

  span {
    font-size: 16px;
    color: #505d67;
  }
}
