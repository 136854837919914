.alignGraphSubTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .leftSection {
    color: var(--color-advancly-dark-persian-indigo);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem; /* 114.286% */
    letter-spacing: 0.00175rem;
  }

  .rightSection {
    color: var(--colorMidPurple);
    font-weight: bold;
    font-size: 14px;
  }
  a {
    color: inherit;
  }
}
