.bvnAndNinInformation {
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  .cardInformation {
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 0.5rem;
    padding: 1.6875rem 1.0625rem;

    .userImage {
      height: 5rem;
      width: 5rem;
      object-fit: fill;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    h1 {
      color: var(--colorDarkIndigo);
      text-align: center;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.6875rem;
      letter-spacing: -0.0031rem;
    }

    .cardItem {
      background-color: #ffffff;
      padding-top: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 0.25rem;
      margin-bottom: 0.5rem;
      height: 3.5rem;

      p {
        color: #170d33;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
}
