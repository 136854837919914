.cardSection {
  color: var(--colorWhite);
  background-color: var(--colorBackgroundBlue);
  padding: 1rem;
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  margin-bottom: 1.5rem;
}

.questionSegment {
  border: 1px solid var(--colorMidGrey);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;

  .newQuestionProperty {
    display: flex;
    justify-content: space-between;

    .productTypeContainer {
      width: 66%;
    }

    .activeStatus {
      width: 30%;
    }
  }

  .answerWeighting {
    margin-top: 1rem;
    display: flex;
    align-items: center;

    &::after {
      display: inline-block;
      width: calc(100% - 3rem);
      height: 1px;
      background-color: var(--colorMidGrey);
      content: " ";
      margin: 0 auto;
    }
  }
}

.filterContainer {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0.5rem 0 0;

  .filterItem {
    position: relative;
    width: 10rem;

    .FilterInput {
      border: 1px solid var(--colorMidBlue);
      width: 10rem;
      height: 2.7rem;
      border-radius: 4px;
      padding: 0 1rem;
      color: #677684;
      font-size: 0.875rem;
      margin-right: 1rem;
    }

    .Icon {
      position: absolute;
      right: 0.8rem;
      top: 0.8rem;
      color: #677684;
    }
  }
}
