.uploadContainer {
  width: 70rem;
  height: 44rem;
  margin: 1.5rem auto;
  background-color: #ffffff !important;
  border: 1px solid #d7dce0;
  border-radius: 5px;

  .radioContainer {
    margin: 2rem auto;
    width: 29rem;
  }

  .title {
    text-align: center;
    margin-top: 4rem;
  }

  .uploadCard,
  .divider,
  .uploadedDoc {
    width: 29rem;
  }

  .uploadCard {
    height: 15rem;
    border: 1px solid #d7dce0;
    margin: 2.3rem auto;
    background-color: var(--colorMidLightBlue);
    border-radius: 4px;

    .header {
      text-align: center;
      font-weight: bolder;
      color: #170d33;
      margin-bottom: 0.5rem;

      & + p {
        color: #8b8699;
        text-align: center;

        .browseAction {
          color: var(--colorBackgroundBlue);
          cursor: pointer;
        }
      }
    }

    .automaticSection {
      height: inherit;
      display: flex;
      justify-content: center;
      margin-top: 4rem;

      .dropdownButton {
        height: 3rem;
        display: flex !important;
      }

      .autoSectiontext {
        color: #8b8699;
      }
    }

    .iconContainer {
      display: flex;
      justify-content: center;
      margin-top: 5rem;
      margin-bottom: 0.5rem;
    }
  }

  .divider {
    border: 1px solid #d7dce0;
  }

  .uploadedDoc {
    margin: 0 auto;

    & > h3 {
      color: #170d33;
      margin-bottom: 1rem;
    }

    & > .uploadedItem {
      position: relative;

      p {
        font-size: 0.8rem;
        padding-left: 1.5rem;
        background-image: url("../../assets/svg/icons/folder_2.svg");
        background-repeat: no-repeat;
        word-wrap: break-word;
      }

      .DownloadDealSlip {
        font-weight: 900;
        color: var(--colorBackgroundBlue);
        cursor: pointer;
      }

      .deleteIcon {
        margin-top: -0.4rem;
        margin-left: 0.5rem;
        cursor: pointer;
      }
    }
  }

  .sendDocument {
    margin: 3rem auto;
    display: block;
    width: 29rem;
  }
}

@media only screen and (max-width: 1198px) {
  .uploadContainer {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 760px) {
  .uploadContainer {
    .commentsContainer {
      margin: 0 auto;
      width: 80%;
      padding-right: 2rem;

      .commentsSection {
        height: 10rem;
        overflow-y: scroll;

        .existingComments {
          margin-top: 2rem;

          & > p:first-of-type {
            font-weight: 900;

            & > span {
              color: rgb(154, 149, 149);
              font-weight: 100;
              margin-left: 0.5rem;
            }
          }

          .comments {
            padding-bottom: 1rem;
            border-bottom: 2px solid #d7dce0;
          }
        }
      }
    }
  }
}
