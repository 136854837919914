@media (min-width: 576px) {
  .Modal__dialog {
    max-width: 400px;
    margin: 1.75rem auto;
  }

  .Modal__content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }

  .Modal__dialog.lg-modal {
    margin: 1.75rem auto;
    max-width: 1000px !important;
    min-width: 1200px !important;
  }

  .Modal__content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
}
