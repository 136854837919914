.Initials {
    display: inline-block;
    background-color: #F2F5F9;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    padding-top: 0.5rem;
    padding-left: 0.9rem;
    font-size: 1rem;
    font-weight: 600;
}