.Card{
    max-width: 400px;
    margin: 1.75rem auto;
}

.Card h3{
    font-weight: 700;
    text-align: center;
}

.Card p.amount{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.Card small.amount{
    margin-bottom: .5rem;
    display: block;
    color: #B7B8B9;
    text-align: center;
}

.AggregatorCard .adv-clients{
    margin: 0 auto !important;
}

.AggregatorCard .biz_name{
    font-weight: 600;
    font-size: 0.825rem;
    margin-bottom: 0.2rem !important;
}

.AggregatorCard .biz_icon{
    margin: 0.4rem !important;
}

.SubscriptionCard .biz_name{
    font-weight: 600;
    font-size: 0.825rem;
    margin-bottom: 0.2rem !important;
}