.noGraphData {
  position: absolute;
  left: 35%;
  top: 50%;
  max-width: 50%;
  text-align: center;
  color: #505d67;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    height: 30px;
    width: 30px;
    margin-bottom: 20px;
  }
}
