.tier {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 0.625rem;

  .tierTitle {
    margin-bottom: 1rem;
    color: #170d33;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
