.investmentLayout {
  .investmentLayoutCard {
    font-size: 16px;
    line-height: 16px;
    border: 1px solid #d7dce0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 2rem;
    padding: 1.5rem;

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      padding-bottom: 8px;
      color: #24292e;
    }
    .content {
      padding-bottom: 1.5rem;
      color: #677684;
    }
    .link {
      padding-top: 1.5rem;
      color: #713fff;
    }
    .link a:hover {
      color: rgb(0, 0, 0);
      text-decoration: underline;
    }

    .percent {
      color: #713fff;
    }
  }
  .investmentLayoutCard:hover {
    border: 1px solid #713fff;
  }
}

.inputIcons {
  width: 100%;
  margin-bottom: 10px;
  .icon {
    position: absolute;
    padding: 10px;
    min-width: 44px;
  }

  .inputField {
    width: 100%;
    padding: 10px;
    text-align: center;
    border: 1px solid #d7dce0;
    border-radius: 4px;
  }
  .horizontalLine {
    width: 10%;
  }
}
