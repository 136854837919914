.EcosystemModal .Modal__content {
    height: 120px; 
    overflow: hidden;
}

.EcosystemModal .Modal__content .left{
    height: 120%;
    flex: 1 1 20%;
    background: #041644;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 3px
}

.EcosystemModal .Modal__content .left i{
    color: #1393ea;
    display: inline-block;
}


.EcosystemModal .Modal__content .right{
    height: 100%;
    flex: 1 1 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.EcosystemModal .Modal__content .right .subscribe{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.EcosystemModal .Modal__content .right .loader{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

@media (min-width: 576px){
    .EcosystemModal.Modal__dialog {
        max-width: 400px;
        margin: 1.75rem auto;
    }
}