.downloadButton {
  color: var(--colorMidBlue);
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
}

.retryText {
  color: var(--colorMidBlue);
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

.photoUrl {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 50px;
    width: 50px;
    border: 50%;
  }
}

.uploadInstructions {
  padding: 0px;
  margin-left: 30px;
}
