.profileArea {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #d7dce0;
  border-bottom: 1px solid #d7dce0;
  margin-bottom: 1.5rem;

  .profileAreaCard {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    letter-spacing: 0.4px;
    padding: 1.5rem 0px;

    .title {
      color: #677684;
      font-weight: 600;
      padding-bottom: 1rem;
    }
    .content {
      color: #0d0f11;
    }
  }
}
.checkbox {
  svg {
    fill: #00a006;
  }

  &.fail {
    svg {
      fill: #e43535;
    }
  }
}

.filterContainer {
  display: flex;
  justify-content: flex-end;

  .filterItem {
    position: relative;
    width: 10rem;

    .FilterInput {
      border: 1px solid #d7dce0;
      width: 10rem;
      height: 2.6rem;
      border-radius: 4px;
      padding: 0 1rem;
      color: #677684;
      font-size: 0.875rem;
      margin-right: 1rem;
    }

    .Icon {
      position: absolute;
      right: 0.8rem;
      top: 0.8rem;
      color: #677684;
    }
  }
}

.uploadedDocsContainer {
  .documentTimeStamp {
    display: inline-block;
    color: #b2aebb;
    font-size: 0.875rem;
    width: 10rem;
    margin: 1.6rem 1.5rem;
  }
  .commentsAction {
    display: flex;
    margin: auto;
    cursor: pointer;

    & .messageIcon {
      margin-top: 0.23rem;
    }

    & .commentText {
      display: inline-block;
      margin-left: 0.5rem;
      color: var(--colorBlue);
    }
  }

  .downloadUploadDocContainer {
    width: 20rem;
  }
}

.innerLayout {
  max-width: 100% !important;
}

.offerLetterCardContainer {
  width: 100% !important;

  .aggregatorsComments {
    overflow-y: scroll;
    height: 15rem;
  }

  .backButton {
    display: flex;
    margin: 0;
    padding: 0;
    margin-right: 1.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    // height: clamp(20px, 2.5vw, 24px);
    // width: clamp(20px, 2.5vw, 24px);
    line-height: 28px;
    color: #200865;

    svg {
      height: 1rem;
      width: 1rem;
      color: #200865;
      fill: #200865 !important;
    }
  }

  .offerLetterUploadContainer {
    width: 20rem;
  }
}
