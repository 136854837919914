.Hamburger {
  line-height: 40px;
  display: inline-block;
  padding: 0px 15px;
  vertical-align: top;
  box-sizing: border-box;
}

.Hamburger:hover {
  /* color: #051745; */
  background-color: #fff;
}

.Hamburger i {
  vertical-align: middle;
}
