.customSelect {
  position: relative;

  label {
    display: block;
    margin-bottom: 10px;

    i {
      margin-right: 20px;
    }
  }

  .customLabelWithBadge {
    display: flex;
    justify-content: space-between;

    & > span {
      display: inline-block;
    }
  }

  .selectBox {
    border-radius: 8px;
    padding: 6px 10px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid #ced4da;
    color: #495057;
    height: 3rem;
  }
}
