.ActionContainer {
  display: flex;
  justify-content: end;

  .FormButtonContainer {
    margin-bottom: 2rem;

    .CreateActionButton {
      height: 2.6rem;
    }
  }
}

@media only screen and (max-width: 850px) {
  .ActionContainer {
    flex-direction: column;

    .FormButtonContainer {
      display: flex;
      justify-content: center;
    }
  }
}
