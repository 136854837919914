.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;
}

.modal_container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% - 200px);
    width: 90%;
    background-color: #FFF;
    border-radius: 20px;
    border-top: 4px solid #CFBEFF;
    box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.10);
    padding: 20px;
    position: fixed;
    overflow-y: scroll;
    z-index: 10;

    @media (min-width: 1280px) {
        width: 70%;
    }

    .cancel_button {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    h4 {
        text-align: center;
    }

    .form_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .form_item {
            display: flex;
            flex-direction: column;
        }

        .btn_container {
            margin-top: 20px;
           
            .btn_lite {
                font-size: 12px;
                margin: auto;
                display: inline-block;
                font-weight: 400;
                padding: 0.7rem;
                user-select: none;
                outline: 0 !important;
                background-color: transparent;
                border-radius: 8px;
                min-width: 120px;
                border: 1px solid #713fff;
                box-shadow: 0 6px 8px rgba(0, 0, 0, 0.06);
                height: auto;
                transform: scale(1);
                transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
                line-height: 1.25;
            }

            .btn {
                font-size: 12px;
                margin: auto;
                display: inline-block;
                font-weight: 400;
                padding: 0.7rem;
                user-select: none;
                outline: 0 !important;
                background-color: #713fff;
                border-radius: 8px;
                min-width: 120px;
                color: #fff;
                border: 1px solid rgba(0, 0, 0, 0.19);
                box-shadow: 0 6px 8px rgba(0, 0, 0, 0.06);
                height: auto;
                transform: scale(1);
                transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
                line-height: 1.25;
            }

        }
    }

    input {
        border-radius: 8px;
        padding: 0.5rem 1rem 0.5rem 2rem;
        font-size: 0.9rem;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.19)
    }

    textarea {
        border-radius: 8px;
        padding: 0.5rem 1rem 0.5rem 2rem;
        font-size: 0.9rem;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.19)
    }
}