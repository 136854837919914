.errorHandler {
  margin-top: -10px !important;
  margin-bottom: 1rem !important;
  opacity: 0.8;
}

.inputInfo {
  margin-top: 0.2rem !important;
  opacity: 0.8;
  text-align: left;
  padding-left: 5px;
}

.flex-left {
  float: left !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.userRole {
  padding: 6px 15px;
}

.card-btn {
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-decoration: none;
  padding: 10px 25px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  background: #f9f9f9;
  color: #121212;
  display: inline-block;
  border-radius: 25px;
}
.card-btn:hover {
  background: #041644;
  color: #fff;
  opacity: 1 !important;
}

.hover-item {
  cursor: pointer !important;
}

#adv-aggregator {
  margin-top: 13px;
}

#adv-tokenDialog {
  margin: 3em auto;
}

.error-text {
  font-size: 13px;
  text-align: center;
  letter-spacing: 3px;
  text-transform: capitalize;
}

.error-element {
  color: crimson !important;
  /* padding-left: .2rem; */
}

:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
summary {
  display: block;
  margin: 0;
  height: auto;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 90%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

code,
kbd,
pre,
samp {
  font-size: 1em;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

textarea {
  overflow: auto;
}

::-webkit-input-placeholder {
  color: #0d0d0d;
  opacity: 0.84;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  color: #303030;
  margin-bottom: 0;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 21px;
}

h3 {
  font-size: 19px;
}

h4 {
  font-size: 17px;
  letter-spacing: 1.5px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 13px;
}

p,
#newsletter p {
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 400;
}

p {
  color: #0d0d0d;
}

#newsletter p {
  color: #fff;
}

p span {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
}

a {
  text-decoration: none !important;
  color: inherit;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

a:hover {
  color: #c61c2f;
}

a,
input,
textarea,
button,
select {
  outline: none;
}

.animated {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.center-txt {
  text-align: center !important;
}

.left-txt {
  text-align: left !important;
}

.right-txt {
  text-align: right !important;
}

a:hover,
h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus,
h6:focus {
  text-decoration: none;
}

a:focus,
button:focus,
select:focus,
input:focus,
textarea:focus {
  outline: none;
  text-decoration: none;
}

.uppercase {
  text-transform: uppercase !important;
}

.hidden {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
  visibility: hidden !important;
}

.relax-right {
  float: right;
}

.relax-left {
  float: left;
}

.pb-30 {
  padding-bottom: 30px !important;
}

#root {
  height: 100vh;
  width: 100vw;
}

.palette1 {
  color: #2a2e8d !important;
}

.palette2 {
  color: #fa7615 !important;
}

.palette3 {
  color: #a5d32f !important;
}

.palette4 {
  color: #1b6eb8 !important;
}

.palette5 {
  color: crimson !important;
}

.palette6 {
  color: #a3218e !important;
}

.white-bg {
  background: #fff;
}

.light-txt {
  color: #fff;
}

.dark-text {
  color: #0d0d0d;
}

.bold-font {
  font-weight: 900;
}

.capitalize {
  text-transform: capitalize !important;
}

.for-mobile {
  display: none !important;
}

.for-pc {
  display: inline-block !important;
}

section {
  display: inline-block !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.text-color {
  color: #000;
}

.center-elem {
  float: none !important;
  margin: 1em auto !important;
}

.back-btn {
  margin-top: -44px !important;
}

.zoom-element {
  height: auto;
  display: block;
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -moz-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -ms-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
}

.zoom-element:hover,
.zoom-out {
  cursor: pointer !important;
  -ms-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -moz-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -ms-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
}

#error-msg {
  margin-left: 13px;
}
.m-b-auto {
  margin-bottom: 0.5rem;
}

.tfx-loading {
  overflow: hidden !important;
  width: 100vw;
  height: 100vh;
}

.tfx-content {
  display: block;
  width: 100%;
  float: none;
  margin: 0 auto;
  background: #fff;
}

.tfx-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background-image: url(../images/LoadingScreen.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  display: block;
  overflow: hidden !important;
}

// .tfx-icon {
//   display: block;
//   vertical-align: middle;
//   margin: 0 auto;
//   background: url(../images/advancly-loader.png) 50% 50% no-repeat transparent;
// }

.mt-30 {
  margin-top: 30px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-50 {
  margin-top: 50px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20,
.mt-result {
  margin-top: 20px !important;
}

.ecosystem {
  height: 120px !important;
}

.filterTab {
  padding: 1em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.filterTab:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05) !important;
}

.filterItem {
  padding: 1em;
  margin-left: 1rem;
  background-color: unset !important;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #0d0f11;
  cursor: pointer;
  text-align: center;
}

.filterItem:first-child {
  margin-left: 0 !important;
}

.filterItem i {
  opacity: 1;
}

.opac-5 {
  opacity: 0.4 !important;
}

.filterItem.active {
  transition: left 0.2s ease;
  -webkit-transition: left 0.2s ease;
  border-bottom: 2px solid #8d65ff !important;
  border-radius: unset !important;
  background-color: var(--colorMidBlue);
  color: #713fff;
}

.filterItem.active i {
  color: #713fff !important;
}

.tfx-wrapper {
  width: 100%;
  position: relative;
  display: block;
  margin: 0 auto;
  float: none;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.mt-15 {
  margin-top: 15px !important;
}

.lead {
  font-size: 1rem;
  font-weight: 500;
}

.tfx-header {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  display: inline-block;
  position: relative;
}

.tfx-header .tfx-logo {
  cursor: pointer !important;
}

.adv-clients {
  padding: 0.5rem;
  height: 50px !important;
  width: auto;
  display: table-cell;
  vertical-align: middle;
  margin: 10px auto;
  float: none;
}

.aggSelect:hover {
  border: 2px solid #713fff !important;
}

.full-border {
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
}

.right-border {
  border-right: 1px solid rgba(0, 0, 0, 0.09) !important;
}

.left-border {
  border-left: 1px solid rgba(0, 0, 0, 0.09) !important;
}

.top-border {
  border-top: 1px solid rgba(0, 0, 0, 0.09) !important;
}

.bottom-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09) !important;
}

.tfx-header .tfx-header__top {
  float: left;
  display: inline-block;
  width: 100vw;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  margin: 0;
  height: auto;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.tfx-header .tfx-header__top:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05) !important;
}

.center-block {
  color: #dcdcdc !important;
  font-weight: 400 !important;
  display: table-cell;
  vertical-align: middle;
  width: 100vw;
  height: 70vh;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.tfx-header .tfx-header__top p {
  margin-bottom: 0;
  font-size: 13px;
  text-align: left;
  color: #0d0d0d;
  line-height: 40px;
}

@media (max-width: 768px) {
  .mt-result {
    margin-top: 78px !important;
  }

  .grid__padding__right {
    padding: 0 15px !important;
  }

  .grid__padding__left {
    padding: 0 15px !important;
  }

  .tfx-header .navigation {
    padding: 0;
    min-height: 50px;
    background-color: #fff;
  }

  .tfx-header .menu-toggle {
    display: inline-block;
    float: right;
  }

  .tfx-result-icon,
  .tfx-result-search {
    width: 50% !important;
  }

  .tfx-result-takeoff,
  .tfx-result-landing {
    width: 100% !important;
  }

  .mobile-view {
    display: inline-block;
  }
}

/*upload image on Funder onboarding*/

.uploadImage {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.uploadImage + label {
  font-size: 15px;
  font-weight: 400;
  padding: 8px;
  color: white;
  background-color: #713fff;
  display: inline-block;
  border-radius: 5px;
  transition: all 0.4s;
}

.uploadImage:focus + label,
.uploadImage + label:hover {
  cursor: pointer;
  transform: translateY(3px);
  box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.3);
}

.tfx-header__logo {
  cursor: pointer !important;
}

.mobile-menu,
#cart-wrapper,
.page-loader,
#search-modal,
#more-details {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  padding-top: 150px;
  z-index: 9;
  background: #fff;
  display: none;
  overflow: hidden !important;
  box-shadow: 0 8px 6px rgba(0, 0, 0, 0.04);
}

.mobile-menu.active,
#cart-wrapper.active,
.page-loader.active,
#search-modal.active,
#more-details.active {
  display: block !important;
}

#cart-wrapper,
#search-modal,
#more-details {
  padding-top: 70px !important;
}

.nm {
  margin: 0 !important;
}

.np {
  padding: 0 !important;
}

.Hamburger,
.Bell {
  line-height: 51px !important;
  display: inline-block;
  padding: 0px 15px;
  vertical-align: top;
  box-sizing: border-box;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.Hamburger:hover,
.Bell:hover {
  background: transparent !important;
  color: #713fff !important;
  cursor: pointer !important;
}

.Hamburger i,
.Bell i {
  font-size: 1.5em !important;
}

.mobile-view {
  display: none;
}

.menu-toggle {
  position: relative;
  top: 7px;
  width: 40px;
  height: 40px;
  margin-left: 20px;
  overflow: hidden;
  display: none;
  background-color: #713fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.menu-toggle span,
.menu-toggle:before,
.menu-toggle:after {
  position: absolute;
  right: 10px;
  height: 2px;
  width: 20px;
  background-color: #fff;
  z-index: 100;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.menu-toggle span {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.menu-toggle:before,
.menu-toggle:after {
  content: "";
}

#more-details.active {
  display: block !important;
  overflow-y: scroll !important;
}

.menu-toggle:before {
  top: 12px;
}

.menu-toggle:after {
  bottom: 12px;
}

.menu-toggle:hover {
  cursor: pointer;
}

.menu-toggle.activated {
  background-color: #c61c2f;
}

.menu-toggle.activated span {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  visibility: hidden;
  opacity: 0;
}

.menu > li > a.active {
  color: #a5d32f !important;
}

.menu-toggle.activated:before,
.menu-toggle.activated:after {
  top: 50%;
  bottom: auto;
  width: 20px;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.menu-toggle.activated:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-toggle.activated:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu {
  display: inline-block;
  vertical-align: top;
}

.menu::after {
  clear: both;
  content: "";
  display: table;
}

.menu > li {
  display: inline-block;
  float: left;
  margin-right: 10px;
}

.menu > li > a {
  position: relative;
  z-index: 10;
  display: inline-block;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 50px;
  margin-top: -4px;
  text-transform: uppercase;
  color: #0d0d0d;
}

.menu > li > a:hover {
  color: #2a2e8d !important;
}

.menu > li > a {
  color: #010101;
  width: 100%;
  text-shadow: none;
  padding: 7px;
  position: relative;
  display: inline-block;
  margin: 0 auto;
  float: left;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 14px;
}

.menu > li > a.active::before,
.menu > li > a::before {
  color: #c61c2f !important;
  content: attr(data-hover);
  position: absolute;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}

.router-link-exact-active a::after,
.menu > li > a.active::after {
  opacity: 0.85 !important;
  -webkit-transform: translateY(0) !important;
  -moz-transform: translateY(0) !important;
  transform: translateY(0) !important;
}

.menu > li > a::after,
.router-link-exact-active a::after,
.menu > li > a.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #fa7615;
  opacity: 0;
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  transform: translateY(5px);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}

.menu > li > a:hover::before,
.menu > li > a:focus::before {
  opacity: 0;
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  transform: translateY(-2px);
}

.menu > li > a:hover::after,
.menu > li > a:focus::after {
  opacity: 0.85;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}

.navigation {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  float: left;
  position: relative;
  color: #fff;
  display: inline-block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  height: auto;
  width: 100%;
  max-height: 60px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  text-decoration: none !important;
}

.navigation:hover,
#footer:hover {
  box-shadow: 0 8px 6px rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.navigation .navigation__column {
  float: none;
  margin: auto;
}

.navigation .navigation__column.left {
  width: 15%;
}

.navigation .navigation__column.center {
  width: 70%;
}

.navigation .navigation__column.right {
  width: 15%;
}

.navigation .center {
  text-align: center;
}

.navigation .navigation__column.right {
  text-align: right;
}

.navigation .navigation__column.right .tfx-cart__toggle {
  top: 6.5px;
}

.cart-details,
.more-details {
  display: inline-block;
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 100px;
  text-align: center;
}

// .center-content {
//   display: table-cell;
//   height: 100vh;
//   width: 100vw;
//   vertical-align: middle;
//   position: relative;
// }

// .center-content-block {
//   display: block;
//   text-align: center;
//   width: 100%;
//   height: 100%;
//   font-size: 1em;
//   color: #0d0d0d;
// }

// .center-content {
//   display: table-cell;
//   height: 80vh;
//   width: 100vw;
//   vertical-align: middle;
//   position: relative;
// }

.mini-icons {
  display: block;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  padding: 13px 8px 8px 8px;
  background-color: #1bb05d;
  border-color: #1bb05d;
  color: #fff;
  cursor: pointer;
  text-align: center !important;
  font-size: 25px;
  margin: 15px auto;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.adv-bg {
  /*background: url(../images/adv-bg.png) top center no-repeat;*/
  background-size: 100% 95%;
}

.adv-form-bg {
  /*background: url(../images/adv-form-bg.png) no-repeat scroll center left 50px / 900px 100%;*/
}

.full-screen {
  width: 100vw !important;
  height: 100vh !important;
}

.section-block {
  height: auto;
  display: block;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.section-view {
  height: auto;
  display: block;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.table-view {
  height: auto;
  display: block;
  width: 100%;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  /*border-bottom: 1px solid rgba(0, 0, 0, 0.08);*/
}

.settings-block {
  height: auto;
  display: block;
  width: 100%;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.glass {
  background: transparent !important;
}

.datatable-wrapper {
  display: block;
  margin: auto;
  height: auto;
  width: 100%;
  min-height: 100vh;
}

.user-profile-wrapper {
  margin: auto 0.5em;
}

@media (max-width: 768px) {
  .navigation .navigation__column {
    width: 100%;
  }

  .navigation .navigation__column.left {
    width: 50%;
  }

  .navigation .navigation__column.center {
    width: 0;
  }

  .navigation .navigation__column.right {
    width: 50%;
  }

  .navigation .navigation__column {
    width: 100%;
  }

  .navigation .navigation__column.left {
    width: 35%;
  }

  .navigation .navigation__column.center {
    width: 0;
  }

  .navigation .navigation__column.right {
    width: 65%;
  }

  .menu > li {
    display: inline-block;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    width: 42%;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.09);
  }

  .tfx-cta {
    height: 100vh;
  }

  .mobile-menu {
    padding-top: 130px;
  }

  .datatable-wrapper {
    margin-top: 90px !important;
  }
}

@media (max-width: 767px) {
  .for-pc {
    display: none !important;
  }

  .for-mobile {
    display: inline-block !important;
  }

  .grid__padding-xmobile {
    padding: 0 !important;
  }

  .user__account > a,
  .contact__line > a,
  .user__account > span,
  .contact__line > span {
    padding: 0 5px !important;
  }

  .user__account {
    width: 50%;
    padding: 0;
  }

  .contact__line {
    width: 100%;
    padding: 0;
  }

  .navigation .navigation__column {
    width: 100%;
  }

  .navigation .navigation__column.left {
    width: 60%;
  }

  .navigation .navigation__column.right {
    width: 40%;
  }

  .tfx-header {
    position: fixed;
  }

  .tfx-user-form-wrapper {
    width: auto !important;
    margin: auto 0.5em !important;
    background: #f9f9f9;
  }

  .tab-label-content-details {
    width: 25% !important;
  }

  .more-details-container {
    width: 100% !important;
  }

  .tab-label-content-details label {
    font-size: 10px !important;
  }

  .form-step__step-name {
    font-size: 0.8em !important;
  }

  .user__account > a,
  .contact__line > a {
    font-size: 12px !important;
  }

  .user-profile-wrapper {
    margin: 140px 0.5em 3em 0.5em !important;
  }
}

.center__navigation {
  float: none !important;
  display: block !important;
  margin: auto !important;
}

.menu--active {
  -webkit-transform: translateX(-240px);
  -moz-transform: translateX(-240px);
  -ms-transform: translateX(-240px);
  -o-transform: translateX(-240px);
  transform: translateX(-240px);
}

.menu--active .tfx-header--sidebar {
  overflow-y: auto;
}

.menu--active.navigation--pin {
  -webkit-transform: translate(-240px, 0);
  -moz-transform: translate(-240px, 0);
  -ms-transform: translate(-240px, 0);
  -o-transform: translate(-240px, 0);
  transform: translate(-240px, 0);
}

.menu--active.navigation--unpin {
  -webkit-transform: translate(-240px, -100%);
  -moz-transform: translate(-240px, -100%);
  -ms-transform: translate(-240px, -100%);
  -o-transform: translate(-240px, -100%);
  transform: translate(-240px, -100%);
}

.navigation--sticky {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9 !important;
  transition: all 0.4s ease;
  background: #200865 !important;
}

.navigation--sticky .navigation {
  background-color: #200865 !important;
}

@media (max-width: 768px) {
  .navigation--sticky {
    background-color: #200865 !important;
  }

  .navigation--sticky .menu-toggle span,
  .navigation--sticky .menu-toggle:before,
  .navigation--sticky .menu-toggle:after {
    background-color: #200865;
  }

  .navigation--sticky.tfx-header--2 .tfx-logo {
    margin-top: 5px;
  }

  .cart-details,
  .more-details {
    display: inline-block;
    width: 100%;
    height: 100vh;
    position: relative;
    padding-top: 50px;
    text-align: center;
    overflow-y: auto;
  }

  .tab-label-content .tab-content {
    margin-bottom: 90px;
  }

  label.tabs {
    font-size: 13px;
  }
}

.navigation--pin {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.navigation--unpin {
  -webkit-transform: translate(0, -100%);
  -moz-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  -o-transform: translate(0, -100%);
  transform: translate(0, -100%);
}

.user__account {
  float: right !important;
}

.contact__line {
  float: left !important;
}

.user__account > a,
.contact__line > a,
.user__account > span,
.contact__line > span {
  width: 100%;
  display: inline-block;
  padding: 0 15px;
  font-size: 13px;
  text-align: center;
  color: #0d0d0d;
  text-transform: capitalize;
  line-height: 51px;
  border-left: 1px solid rgba(0, 0, 0, 0.09);
  vertical-align: top;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
}

.user__account:hover,
.contact__line:hover {
  background: #041644;
  color: #fff;
}

.user__account:hover a,
.contact__line:hover a,
.user__account:hover span,
.contact__line:hover span {
  color: #fff;
}

.tfx-user-form-wrapper,
.tfx-booking-form-wrapper {
  margin: 0 auto;
  display: block;
  text-align: center;
  overflow-x: hidden !important;
  overflow-y: auto;
  background: #f9f9f9;
}

.tfx-user-form-inner {
  padding: 1rem;
}

.tfx-forgot-password,
.tfx-sign-up {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tfx-forgot-password a,
.tfx-forgot-password label,
.tfx-sign-up a,
.tfx-sign-up label {
  font-weight: 400;
  color: #1a1a1a;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  text-align: center;
  float: left;
  font-size: 13px;
}

.tfx-forgot-password span,
.tfx-forgot-password span,
.tfx-sign-up span,
.tfx-sign-up span {
  display: inline-block;
  float: left;
  font-weight: 400;
  color: #1a1a1a;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  text-align: center;
  font-size: 13px;
}

.tfx-result-form-wrapper {
  margin: 0 auto;
  display: block;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.035);
  overflow-y: auto;
  padding-bottom: 30px;
}

.tfx-user-form-wrapper {
  max-width: 450px;
  width: 100%;
}

.tfx-getStarted-wrapper {
  width: 70%;
  margin: auto;
}

.tfx-booking-form-inner {
  padding: 0;
}

.tfx-result-search i,
.tfx-result-icon i {
  text-align: center;
  font-size: 17px;
  line-height: 39px;
}

.tfx-result-icon,
.tfx-result-search,
.tfx-result-takeoff,
.tfx-result-landing {
  color: #0d0d0d;
  width: auto;
  padding: 15px !important;
  display: inline-block;
  float: left;
}

.login-btn,
.signup-btn,
.search-btn,
.more-btn,
.hotel-btn,
.prev-btn,
.next-btn,
.book-btn,
.done-btn,
.select-btn,
.rating-btn {
  font-size: 1.25rem;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.19) !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.06);
}

.book-btn,
.next-btn,
.select-btn {
  cursor: pointer;
  background-color: #713fff;
}

.done-btn,
.rating-btn {
  background: #a5d32f;
}

.small-spacing {
  width: 15px;
  display: inline-block;
  margin: auto;
}

.login-btn,
.signup-btn {
  /*background-color: #FA7615;*/
  background-color: #713fff;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 8px;
  min-width: 120px;
}

.reject_btn {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 8px;
  min-width: 120px;
  background-color: #fff;
  font-size: 1.25rem;
  color: #713fff;
  border: 1px solid #713fff !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.06);
}

.search-btn,
.more-btn,
.hotel-btn,
.prev-btn {
  background-color: #713fff;
}

.login-btn:hover,
.signup-btn:hover,
.search-btn:hover {
  cursor: pointer !important;
  background-color: #5d5757 !important;
  color: #fff !important;
  box-shadow: none !important;
}

.more-btn,
.hotel-btn,
.book-btn,
.rating-btn,
.select-btn {
  width: 100% !important;
  height: 100% !important;
}

.login-btn:hover i,
.signup-btn:hover i,
.search-btn:hover i {
  color: #713fff;
}

.form-group {
  margin-bottom: 1rem;
}

select:not(.table-fetch-select) {
  -webkit-appearance: none !important;
}

.form-control {
  border-radius: 8px;
  display: block;
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 2rem;
  font-size: 0.9rem;
  line-height: 1.25;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  font-weight: 400;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  height: 3rem !important;
}

.custom-select-element {
  width: 50% !important;
  //height: 3rem !important;
}

.form-control:focus,
select:hover,
.select-styled:hover {
  -webkit-box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.08);
}

.user__form.form-control:focus {
  border: 2px solid #713fff !important;
  -webkit-box-shadow: 0 0 3px rgba(21, 21, 21, 0.2) !important;
  -moz-box-shadow: 0 0 3px rgba(21, 21, 21, 0.2) !important;
  box-shadow: 0 0 3px rgba(21, 21, 21, 0.2) !important;
}

.form-control.error {
  border: 2px solid crimson !important;
  -webkit-box-shadow: 0 0 3px rgba(21, 21, 21, 0.3) !important;
  -moz-box-shadow: 0 0 3px rgba(21, 21, 21, 0.3) !important;
  box-shadow: 0 0 3px rgba(21, 21, 21, 0.3) !important;
}

.form-control-label {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
}

.custom-select {
  border-color: rgba(120, 130, 140, 0.13);
}

.form-control::-webkit-input-placeholder {
  color: #0d0d0d;
  opacity: 0.7;
}

.form-control::-moz-placeholder {
  color: #0d0d0d;
  opacity: 0.7;
}

.form-control:-ms-input-placeholder {
  color: #0d0d0d;
  opacity: 0.7;
}

.form-control-spin {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 50%;
  margin-top: -7px;
}

.input-group-addon {
  padding: 0 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  color: #55595c;
  text-align: center;
  background-color: #fff;
  width: 43px !important;
  height: 38px !important;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}

.input-group-addon:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.input-group .form-control,
select.form-control {
  cursor: pointer !important;
  display: table-cell !important;
  position: relative;
  z-index: 2;
  font-weight: 400;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.input-group {
  position: relative;
  width: 100%;
  display: table;
  border-collapse: separate;
  border: none;
  background: #fff;
}

.form-group-stack .form-control {
  margin-top: -1px;
  position: relative;
  border-radius: 0;
}

.form-group-stack .form-control:focus,
.form-group-stack .form-control:active {
  z-index: 1;
}

.form-file {
  position: relative;
}

.form-file input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.input-group-sm > .form-control {
  min-height: 1.95rem;
}

.active-checked {
  opacity: 0;
}

input:checked + .active-checked {
  opacity: 1;
}

.facebook {
  background-color: #3f51b5;
  color: rgba(255, 255, 255, 0.87);
}

.google {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.87);
}

.or {
  font-size: 0.8rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.text-white {
  color: white !important;
}

.m-b-sm {
  margin-bottom: 0.5rem;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.btn {
  margin: auto;
  font-weight: 500;
  outline: 0 !important;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.7rem;
  font-size: 0.8rem;
}

[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.rotateicon {
  -webkit-transform: rotateZ(40deg);
  -moz-transform: rotateZ(40deg);
  transform: rotateZ(40deg);
}

.rotateicon-alt {
  -webkit-transform: rotateZ(-40deg);
  -moz-transform: rotateZ(-40deg);
  transform: rotateZ(-40deg);
}

.tfx-check {
  cursor: pointer;
  padding-left: 1.25rem;
  margin: 0 15px 0 0;
  display: inline-block;
  position: relative;
}

#adv-header,
#adv-router,
#adv-footer {
  display: block;
  width: 100%;
  position: relative;
  margin: auto;
  float: left;
}
#adv-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
#resultSet {
  height: auto;
  min-height: 550px;
}

.tfx-check input {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  margin-left: -1.25rem;
}

.tfx-check input:checked + i:before {
  border-width: 0;
  background-color: inherit;
}

.tfx-check input:checked + span .active {
  display: inherit;
}

.tfx-check input[type="radio"] + i,
.tfx-check input[type="radio"] + i:before {
  border-radius: 50%;
}

.tfx-check input[type="checkbox"]:checked + i:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 6px;
  top: 2px;
  display: table;
  width: 6px;
  height: 12px;
  border: 2px solid;
  border-top: 0;
  border-left: 0;
  content: " ";
}

.tfx-check input[type="radio"]:checked + i:after {
  position: absolute;
  left: 5px;
  top: 5px;
  display: table;
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  content: " ";
}

.tfx-check input[disabled] + i:before,
fieldset[disabled] .tfx-check input + i:before {
  opacity: 0.5;
}

.tfx-check input[disabled]:checked + i:before {
  opacity: 0.9;
}

.tfx-check > i {
  width: 0;
  height: 18px;
  line-height: 1;
  margin-left: -20px;
  margin-top: 1px;
  float: left;
  display: inline-block;
  vertical-align: middle;
  margin-right: 25px;
  position: relative;
}

.tfx-check > i:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 100%;
  border: 2px solid #818a91;
  border-radius: 2px;
}

.tfx-check > i.no-icon:after {
  display: none !important;
}

.blue {
  background-color: #2a2e8d;
  color: rgba(255, 255, 255, 0.87);
}

.dashboard-title .rounder {
  position: relative;
  display: inline;
  float: left;
  margin: -7px 0 0 0;
}

.search-title .rounder {
  position: relative;
  display: inline-block;
  float: none;
  margin: -7px 7px 0 0;
}

.rounder {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.07);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.07);
  background-color: #713fff;
  width: 35px;
  height: 35px;
  line-height: 37px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

/* TAB MENU */

.first-border {
  border-radius: 8px 0 0 8px;
}

.last-border {
  border-radius: 0 8px 8px 0;
}

.services-tab {
  display: block;
  margin: auto;
}

.services-tab__container {
  width: 100%;
  margin: 0 auto;
  display: block;
}

.services-tab__nav {
  width: 100%;
  list-style: none;
  padding: 0;
  /*background: #fff;*/
  /*border: 1px solid rgba(0,0,0,0.08);*/
  margin-bottom: 0 !important;
}

.services-tab__list-item {
  display: inline-block;
  width: 25%;
}

.services-tab__link-item {
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  background-color: #fff;
  padding: 15px;
  color: #121212;
  font-size: 14px;
  font-weight: bold;
  transition: 0.15s ease-in-out;
}

.services-tab__link-item.is-active,
.services-tab__link-item.is-active:hover {
  background-color: #713fff;
  color: white !important;
}

.services-tab__link-item.is-active i {
  color: #fff !important;
}

.services-tab__link-item:hover {
  background-color: #051745;
  color: white;
  cursor: pointer;
  transition: 0.15s ease-in-out;
}

/*TAB INPUT STYLES*/
.form__group {
  display: block;
  text-align: start;
}

.input__styles {
  padding: 3px;
}

/* TAB ITEMS */
.services-tab__tab-item {
  /*background: #fff;*/
  /*border-bottom: 1px solid rgba(0,0,0,0.08);*/
  /*border-right: 1px solid rgba(0,0,0,0.08);*/
  /*border-left: 1px solid rgba(0,0,0,0.08);*/
  margin-bottom: 0 !important;
  padding: 20px;
  display: none;
  color: #121212;
  transition: 0.15s ease-in-out;
}

.services-tab__tab-item.is-visible {
  display: inline-block;
  position: relative;
  width: 100%;
  transition: 0.15s ease-in-out;
  min-height: 560px;
  height: auto;
}

.services-tab__list-content {
  /*box-shadow: 0 2px 10px rgba(0,0,0,0.05) !important;*/
}

footer {
  border-top: 6px solid #f2f2f2;
  -webkit-box-shadow: inset 0px 0px 5px 4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0px 0px 5px 4px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0px 0px 5px 4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  color: #fff;
  background: #fff;
}

footer h4 {
  color: #ffffff;
  font-weight: 600;
}

footer h4:after,
h4.cta:after {
  content: "";
  display: block;
  width: 18px;
  height: 3px;
  background-color: #c9302c;
  margin-top: 12px;
  margin-bottom: 15px;
}

footer ul li a,
.subfooter li a {
  font-weight: 500;
  width: 100%;
  display: inline-block;
  float: left;
  color: #091431;
  text-align: left;
  font-size: 12px;
}

footer ul li a:hover {
  color: #091431;
}

footer dt {
  color: #ffffff;
  letter-spacing: 1px;
  margin-top: 5px;
  font-weight: 400;
  margin-bottom: 3px;
  cursor: pointer;
}

.subfooter {
  width: 100vw !important;
  margin: auto;
  margin-bottom: 0 !important;
  display: block;
  height: 50px;
  line-height: 30px;
  color: #0d0d0d;
  font-size: 1em;
  letter-spacing: 1px;
  background: #fff;
  padding: 10px;
  position: relative;
}

.subfooter ul {
  margin-bottom: 0;
}

.subfooter li a:hover {
  color: #c9302c;
  cursor: pointer;
  text-decoration: none;
}

.subfooter li,
.list-inline,
.list-inline li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

li.tfx-social {
  margin: 20px 0 0 0;
  font-size: 14px;
  float: left;
  text-align: center;
}

li.tfx-social a {
  width: 36px;
  height: 36px;
  font-size: 20px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  border: 1px solid #fff;
}

li.tfx-social a:hover {
  background: #c61c2f;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.tfx-cert {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.09);
  background: #fff;
  width: 31%;
  height: auto;
  border: 1px solid #fff;
  float: left;
  display: inline-block;
  margin: 20px 2px;
}

.tfx-cert img {
  width: 100%;
  height: 100% !important;
}

.box-effect {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.035);
}

.box-effect-two {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.06);
}

#search-modal {
  background: #7b4397; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #dc2430,
    #7b4397
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #dc2430,
    #7b4397
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.dataTables_wrapper .status {
  padding: 0.2rem 0.75rem;
  border-radius: 28px;
}

.dataTables_wrapper .status-active {
  background: green;
  color: #fff;
}

.dataTables_wrapper .adv-rated {
  color: #fedb37;
}

.dataTables_wrapper .adv-unrated {
  color: #bdbdbd;
}

.dataTables_wrapper .delete,
.dataTables_wrapper .read,
.dataTables_wrapper .report,
.dataTables_wrapper .edit,
.dataTables_wrapper .print,
.dataTables_wrapper #update,
.dataTables_wrapper #read,
.dataTables_wrapper #disable {
  width: auto;
  float: left;
  margin: auto 10px auto auto;
  padding: 0.5rem 0.7rem;
  border-radius: 28px;
  cursor: pointer;
  background: #bdbdbd;
  color: #fff;
  display: inline-block;
  text-align: center;
  min-width: 70px;
}

.dataTables_wrapper #update:hover,
.dataTables_wrapper #read:hover,
.dataTables_wrapper .edit:hover,
.dataTables_wrapper .details:hover,
.dataTables_wrapper .view:hover,
.dataTables_wrapper .read:hover,
.dataTables_wrapper .report:hover,
.dataTables_wrapper .edit:hover,
.dataTables_wrapper .print:hover,
.dataTables_wrapper #update:hover,
.dataTables_wrapper #read:hover {
  background: #713fff;
  cursor: pointer;
}

.dataTables_wrapper #disable:hover,
.dataTables_wrapper .disable:hover,
.dataTables_wrapper .delete:hover,
.dataTables_wrapper .deny:hover {
  background: crimson;
  cursor: pointer !important;
}

.dataTables_wrapper .approve:hover,
.dataTables_wrapper .mifos:hover,
.dataTables_wrapper .disburse:hover,
.dataTables_wrapper .repay:hover,
.dataTables_wrapper .retry:hover {
  background: green;
  cursor: pointer !important;
}

.modal-title-wrapper {
  margin-bottom: 0.8em !important;
  width: 100%;
  display: inline-block;
}

.modal-title {
  float: left;
  margin: auto auto auto 11px;
  line-height: normal;
}

.transparent-bg {
  background: transparent !important;
}

#modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  margin: auto;
}

.modal-layer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1070;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 8px 6px rgba(0, 0, 0, 0.04);
  display: none;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.modal-layer-active {
  display: block !important;
}

.close-modal-layer {
  position: absolute;
  top: 0;
  right: -4px;
  margin: 20px 30px;
}

.close-modal-layer i {
  font-size: 40px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.close-modal-layer i:hover {
  color: #713fff;
  cursor: pointer;
}

.no-hover:hover,
.no-hover {
  border: none !important;
  box-shadow: none !important;
}

.scroll-btn {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  cursor: pointer;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  text-align: center;
}

.scroll-btn > * {
  display: inline-block;
  line-height: 18px;
  font-size: 13px;
  font-weight: normal;
  color: #7f8c8d;
  letter-spacing: 2px;
}

.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *.active {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.data-import-box {
  padding: 1.2rem;
  color: #000;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30%;
}

.data-import-box:hover {
  cursor: pointer !important;
  background: #041644;
  color: #fff !important;
}

.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *:active,
.scroll-btn > *.active {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: white;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.scroll-btn .mouse {
  position: relative;
  display: block;
  width: 32px;
  height: 51px;
  margin: 0 auto 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 30px;
}

.scroll-btn .mouse > * {
  position: absolute;
  display: block;
  top: 29%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  background: #713fff;
  border-radius: 50%;
  -webkit-animation: ani-mouse 2.5s linear infinite;
  -moz-animation: ani-mouse 2.5s linear infinite;
  animation: ani-mouse 2.5s linear infinite;
}

.adv-icon {
  color: #713fff !important;
}
.investment-adv-icon {
  color: #713fff !important;
  margin: 0;
}

.step-next,
.step-prev {
  display: inline-block;
}

.scroll-btn-wrapper {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin-left: -16px;
}

@media (max-width: 769px) {
  .lead {
    font-size: 1rem;
    font-weight: 500;
  }

  .tfx-step {
    margin: auto;
  }

  .webapp-ripple-text,
  .webapp-ripple-message {
    max-width: 91% !important;
  }

  .tfx-getStarted-wrapper {
    width: 100%;
    margin: auto;
  }

  .airline-data,
  .flight-data,
  .payment-data {
    display: inline-block;
    float: left;
    position: relative;
    height: auto;
  }

  .airline-data,
  .flight-data,
  .payment-data,
  .trip-time {
    width: 100%;
  }

  .airline-takeoff,
  .airline-landing,
  .airline-name,
  .trip-breaks {
    width: 50%;
    padding: 10px 0;
  }

  footer ul li a,
  .subfooter li a {
    text-align: center;
  }

  .btn {
    font-size: 12px;
    margin: auto;
    display: inline-block;
  }

  .auto-width {
    width: 100% !important;
  }

  h2 {
    font-size: 19px;
    margin-bottom: 3px;
  }

  h3 {
    margin-bottom: 3px;
    font-size: 17px;
  }

  .step-margin {
    margin-top: 75px !important;
  }

  .hotel-details,
  .hotel-price,
  .hotel-book {
    width: 50%;
    height: 48px;
  }

  .subfooter li,
  .list-inline,
  .list-inline li {
    width: 100%;
  }

  .step-next,
  .step-prev {
    display: block !important;
  }
}

.tfx-step {
  width: 100%;
  max-width: 650px;
  margin: auto;
  float: none !important;
  display: block;
}

.form-steps {
  width: 100%;
  clear: both;
  margin-bottom: 20px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.form-steps:before,
.form-steps:after {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: table;
  content: "";
}

.form-steps:after {
  clear: both;
}

.form-steps__step {
  width: 25%;
  float: left;
  padding: 1em;
  text-align: center;
  position: relative;
}

.form-steps__step:after {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  content: "";
  background: #eee;
  height: 0.4em;
  top: 2.4em;
  left: 50%;
  width: 100%;
  display: block;
  position: absolute;
  z-index: 1;
}

.form-steps__step:before {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  content: "";
  background: transparent;
  height: 0.4em;
  top: 2.4em;
  left: 50%;
  margin-left: -2em;
  width: 4em;
  display: block;
  position: absolute;
  z-index: 2;
}

.form-steps__step--last:after {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none;
}

.form-steps__step--active .form-steps__step-circle {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #713fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: #fff;
}

.form-steps__step--completed:after {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #713fff;
}

.form-steps__step--completed .form-steps__step-circle {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #713fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: #fff;
}

.form-steps__step--completed .form-steps__step-circle .form-steps__step-number {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none;
}

.form-steps__step--completed .form-steps__step-circle .form-steps__step-check {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: inline-block;
}

.form-steps__step--inactive:after {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #eee;
}

.form-steps__step--inactive .form-steps__step-circle {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: #000000;
}

.form-steps__step--inactive .form-steps__step-circle .form-steps__step-number {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: inline-block;
}

.form-steps__step--inactive .form-steps__step-circle .form-steps__step-check {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none;
}

.form-steps__step-circle {
  background: #fff;
  border: 1px solid #bdbdbd;
  line-height: 3em;
  border-radius: 50%;
  height: 3em;
  width: 3em;
  text-align: center;
  color: #000000;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}

.form-steps__step-check {
  display: none;
}

.form-step__step-name {
  margin-top: 0.8em;
  display: inline-block;
  font-size: 1em;
  line-height: 1.4em;
  color: #000;
}

.label-txt {
  position: relative;
  width: 100%;
  color: #000;
  text-align: left;
  font-weight: 400;
  display: inline-block;
  padding-left: 5px;
}

.inputWithIcon input,
.inputWithIcon select {
  padding-left: 40px;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon i {
  z-index: 9;
  position: absolute;
  left: 6px;
  font-size: 1.1rem;
  top: 0;
  padding: 9px 8px;
  color: #091431;
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

.inputWithIcon input:focus + i,
input:focus + i,
.inputWithIcon select:focus + i,
select:focus + i {
  color: #713fff !important;
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f9f9f9;
  opacity: 1;
}
@mixin tags {
  padding: 0.5rem 0.7rem;
  border-radius: 3px !important;
  text-transform: capitalize;
}
@mixin activeTag {
  @include tags();
  background-color: #fdf2e7;
  color: #f08829;
}
@mixin inactiveTag {
  @include tags();
  color: var(--colorMidBlue);
  background-color: #e8effd;
}
@mixin successTag {
  @include tags();
  color: #27be63;
  background-color: #eafbf1;
}
@mixin pendingTag {
  @include tags();
  color: #5e4bce;
  background-color: #edebfa;
}
@mixin errorTag {
  @include tags();
  color: #ca1b1b;
  background-color: #fce9e9;
}
@mixin rejectedTag {
  @include tags();
  color: #ca1b1b;
  background-color: #fce9e9;
}
td {
  .active {
    @include activeTag();
  }
  .inactive {
    @include inactiveTag();
  }
  &.success {
    @include successTag();
  }
  &.repaid {
    @include successTag();
  }
  &.pending {
    @include pendingTag();
  }
  &.error {
    @include errorTag();
  }
  &.rejected {
    @include rejectedTag();
  }
  &.failed {
    @include errorTag();
  }
  &.due {
    @include errorTag();
  }
  &.overdue {
    @include errorTag();
  }
  &.closed {
    @include inactiveTag();
  }
  &.approved {
    @include successTag();
  }
  &.writtenoff {
    @include errorTag();
  }
  &.rescheduled {
    @include pendingTag();
  }
  &.overpaid {
    @include errorTag();
  }
}

td > span {
  &.active {
    @include activeTag();
  }
  &.inactive {
    @include inactiveTag();
  }
  &.success {
    @include successTag();
  }
  &.repaid {
    @include successTag();
  }
  &.pending {
    @include pendingTag();
  }
  &.error {
    @include errorTag();
  }
  &.rejected {
    @include rejectedTag();
  }
  &.failed {
    @include errorTag();
  }
  &.due {
    @include errorTag();
  }
  &.overdue {
    @include errorTag();
  }
  &.closed {
    @include inactiveTag();
  }
  &.approved {
    @include successTag();
  }
  &.writtenoff {
    @include errorTag();
  }
  &.rescheduled {
    @include pendingTag();
  }
  &.overpaid {
    @include errorTag();
  }
}
.verify {
  display: block;
  width: 100%;
  margin-top: 15px !important;
  float: left;
}

select.custom-select {
  margin-top: 0.6em !important;
  height: 35px !important;
  border: 1px solid rgba(0, 0, 0, 0.19);
  font-weight: 400;
}

.dataTables_filter {
  float: right;
  padding: 1em 0;
}

.dataTables_filter .form-control {
  margin-top: 0.6em !important;
}

.dataTables_length {
  float: left;
  padding: 1em 0;
}

.dataTables_length label {
  padding: 1em 0;
}

.adv-avatar {
  position: relative;
  margin: 0 auto 15px auto;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  z-index: 9;
  border: 1px solid rgba(0, 0, 0, 0.02);
}

.adv-avatar img {
  width: 100%;
  display: block !important;
  border-radius: 500px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

img {
  vertical-align: middle;
  border: 0;
}

.pincode-input {
  width: 85% !important;
  margin: auto 1% !important;
  padding: 0.5rem 1rem !important;
  text-align: center !important;
}

.adv-aggregator-icon {
  margin-left: 3px !important;
  margin-top: 2.5px !important;
}

.adv-form {
  display: inline-block;
  padding: 1em 0;
  width: 100%;
}

.adv-check {
  cursor: pointer;
  padding-left: 0.7rem;
  margin: 0 0 0 10px;
  display: inline-block;
  position: relative;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.adv-check input {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  width: 60%;
  margin-left: -1.25rem;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.adv-check input:checked + i:before {
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.adv-check input:checked + span .active {
  display: inherit;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.adv-check input[type="radio"] + i,
.adv-check input[type="radio"] + i:before {
  border-radius: 50%;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.adv-check input[type="checkbox"]:checked + i {
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.adv-check input[type="checkbox"]:checked + i:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 7.5px;
  top: 3px;
  display: table;
  width: 6px;
  height: 12px;
  border: 2px solid;
  border-top: 0;
  border-left: 0;
  content: " ";
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.adv-check input[type="radio"]:checked + i:after {
  position: absolute;
  left: 6px;
  top: 6px;
  display: table;
  width: 6px;
  height: 6px;
  background: #091431;
  border-radius: 50%;
  content: " ";
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.adv-check input[disabled] + i:before,
fieldset[disabled] .adv-check input + i:before {
  opacity: 0.5;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.adv-check input[disabled]:checked + i:before {
  opacity: 0.5;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.adv-check > i {
  width: 0;
  height: 18px;
  line-height: 1;
  margin-left: -20px;
  margin-top: -1px;
  float: left;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: relative;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.adv-check > i:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  transition: all 0.1s ease;
  background: #fff;
}

.adv-check > i.no-icon:after {
  display: none !important;
}

.confirm-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 2em 0 !important;
  width: 100vw;
}
#delete-role-confirm {
  display: table-cell;
  width: 100vw;
  margin: auto;
}
#update-role-confirm {
  display: block;
  width: 100%;
  margin: auto;
}

.import-icon {
  display: block;
  margin: 5px auto;
  font-size: 2.5em;
}

label.form-component {
  margin-bottom: 4px;
  font-weight: 500;
  width: 100%;
  display: inline-block;
  float: left;
  color: #091431;
  text-align: left;
  font-size: 12px;
}

.pincode-padding {
  margin: 25px auto;
  display: block;
  width: 100%;
}

select.round {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-image: -webkit-linear-gradient(45deg, transparent 50%, gray 50%),
    -webkit-linear-gradient(315deg, gray 50%, transparent 50%),
    -webkit-radial-gradient(rgb(221, 221, 221) 70%, transparent 72%);
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    radial-gradient(rgb(221, 221, 221) 70%, transparent 72%);
  background-position: calc(100% - 18.5px) calc(1em + 2px),
    calc(100% - 13.5px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

select.round:focus {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    radial-gradient(gray 70%, transparent 72%);
  background-position: calc(100% - 13.5px) 1em, calc(100% - 18.5px) 1em,
    calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}

.adv-select__control {
  border-radius: 20px !important;
  color: #000 !important;
}

.select {
  position: relative;
  height: 35.5px;
  background: transparent;
}

.select:before:hover,
select:hover,
select.round {
  cursor: pointer !important;
}

.select:after {
  content: "";
  position: absolute;
  top: 5px;
  width: 0;
  height: 0;
  right: 15px;
  bottom: 0;
  margin: auto;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #713fff transparent transparent transparent;
  pointer-events: none;
}

.select:before {
  width: 30px;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  background: transparent;
  content: "";
  pointer-events: none;
}

.select select {
  font-size: 14px;
  background: transparent;
  height: 100%;
  width: 100%;
  cursor: pointer;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.08);
  -moz-appearance: none;
  -webkit-appearance: none;
}

.select select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.select select::-ms-expand {
  display: none;
}

.select select:focus {
  border-color: #33691e;
}

.pagination .page-item.active,
.pagination > .active > a,
.pagination > .active > span,
.pager .page-item.active,
.pager > .active > a,
.pager > .active > span {
  color: white !important;
  background-color: #713fff !important;
  border-color: #713fff !important;
}

.table {
  background: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.19) !important;
  padding: 1em;
}

.table-responsive {
  display: block;
  width: 100%;
  min-height: 0.01%;
  overflow-x: auto;
  border: 1px solid rgba(0, 0, 0, 0.14) !important;
}
.table {
  margin-bottom: 0 !important;
}

.dataTables_info {
  margin: 1em auto;
}

.pagination {
  background: #fff !important;
  margin-top: 30px !important;
}

.table thead th i {
  float: right !important;
  cursor: pointer;
}

@media all and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
  .select select {
    padding-right: 0;
  }

  .select:after,
  .select:before {
    display: none;
  }
}

#adv-processing,
.loading-overlay {
  min-height: 250px !important;
  width: 100%;
  overflow: hidden !important;
}

.notifier {
  color: #091431;
}

.increasing-text {
  color: #4caf50;
}

.decreasing-text {
  color: crimson;
}

.success-text {
  color: #713fff !important;
}

.error-text {
  color: crimson !important;
}

.warning-text {
  color: #713fff !important;
}

.default-text {
  color: #0d0d0d !important;
}

.webapp-ripple-text,
.webapp-ripple-message {
  display: inline !important;
  max-width: 400px;
  text-align: center !important;
  width: inherit;
  color: #091431;
  font-weight: 400;
  letter-spacing: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.webapp-ripple-text {
  top: 16vh !important;
  font-size: 15px;
}

.webapp-ripple-message {
  top: 57% !important;
  font-size: 12px;
}

.webapp-ripple-notification {
  font-size: 60px;
  margin-left: -10px;
  margin-top: -10px;
}

.webapp-ripple {
  margin: auto;
  width: 64px;
  height: 64px;
  position: absolute;
  display: block;
  text-align: center;
  top: -30%;
  left: 0;
  right: 0;
  bottom: 0;
}

.webapp-ripple div,
.webapp-ripple div.response {
  position: absolute;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.webapp-ripple div {
  border: 5px solid #713fff !important;
}

.webapp-ripple div.response {
  border: 5px solid #f8f8f8 !important;
}

.webapp-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.centerLoader {
  position: relative;
  display: table;
  vertical-align: middle;
  margin: auto;
  height: 100%;
  width: 100%;
  padding-top: 8%;
}

#adv-loader-alt,
.success-loader {
  margin: auto;
  width: 4em;
  height: 4em;
  background: transparent;
  -moz-border-radius: 2em;
  -webkit-border-radius: 2em;
  border-radius: 2em;
  position: relative;
  border-bottom-color: #f8f8f8;
}

.success-loader {
  border: 0.5em solid #fff;
}

#adv-loader-alt {
  border: 0.5em solid #713fff;
}

#adv-loader-alt::after {
  position: absolute;
  top: 0.75em;
  left: 0.75em;
  width: 1.5em;
  height: 1.5em;
  content: "";
  display: block;
  background: #713fff;
  -moz-border-radius: 1em;
  -webkit-border-radius: 1em;
  border-radius: 1em;
}

.success-loader::after {
  position: absolute;
  top: 0.75em;
  left: 0.75em;
  width: 1.5em;
  height: 1.5em;
  content: "";
  display: block;
  background: #fff;
  -moz-border-radius: 1em;
  -webkit-border-radius: 1em;
  border-radius: 1em;
  opacity: 0;
}

#adv-loader-alt::before {
  position: absolute;
  bottom: 1.5em;
  left: 1em;
  content: "";
  display: block;
  background: transparent;
  transform-origin: 50% 100%;
  border-bottom: 1.5em solid #713fff;
  border-right: 0.5em solid transparent;
  border-left: 0.5em solid transparent;
  animation: adv-loader-alt 3.5s infinite;
}

.success-loader::before {
  position: absolute;
  bottom: 1.5em;
  left: 1em;
  content: "";
  display: block;
  background: transparent;
  transform-origin: 50% 100%;
  border-bottom: 1.5em solid #fff;
  border-right: 0.5em solid transparent;
  border-left: 0.5em solid transparent;
  animation: adv-loader-alt 3.5s infinite;
  opacity: 0;
}

.onboarding-response {
  font-size: 13px !important;
  margin-top: 50px !important;
}

// .center-container {
//   display: table;
//   width: 100vw;
//   margin: auto;
//   text-align: center;
// }

// .center-container .center-container-wrapper {
//   display: table-cell;
//   vertical-align: middle;
// }

// .center-container .center-element {
//   width: 100% !important;
//   margin: 0 auto;
// }

// .center-logo {
//   margin: auto !important;
//   display: block !important;
// }

.card {
  padding: 1em;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
  display: inline-block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.19) !important;
}

.dataTables_filter {
  float: right;
  padding: 1em 0;
}

.table .table-bordered .table-hover .table-striped .dataTable label {
  display: inline;
}

.custom-select .custom-select-sm .form-control .form-control-sm {
  height: 40px !important;
}

.top-report h3 {
  margin: 0;
  color: #3d4c5a;
  font-weight: 400;
}

.progress {
  height: 5px;
  box-shadow: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  background-color: #eceeef;
}

.progress .progress-bar.progress-bar-success {
  background-color: #4caf50;
  width: 68%;
}

.progress .progress-bar-success {
  background-color: #2b982b;
}

.progress .progress-bar {
  line-height: 23px;
  background-color: #713fff;
  border-radius: 0 !important;
}

.progress .progress-bar,
.progress .progress-bar.progress-bar-default {
  background-color: #9c27b0;
}

.progress .progress-bar.progress-bar-danger {
  background-color: #f44336;
  width: 32%;
}

.progress .progress-bar-danger {
  background-color: #fb483a;
}

.progress-bar {
  height: 1rem;
  color: #fff;
  background-color: #713fff;
}

.top-report .progress {
  height: 5px;
  margin-bottom: 10px;
}

.progress .progress-bar.progress-bar-warning {
  background-color: #fbc02d;
}

.progress .progress-bar-warning {
  background-color: #ff9600;
  width: 68%;
}

.progress .progress-bar.progress-bar-info {
  background-color: #713fff;
}

.progress .progress-bar-info {
  background-color: #713fff;
  width: 68%;
}

.progress .progress-bar {
  line-height: 23px;
  background-color: #713fff;
}

.form-nav {
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #091431;
  margin-top: 10px;
}

.speed-1x {
  visibility: visible !important;
  -webkit-animation-duration: 0.7s;
  -moz-animation-duration: 0.7s;
  -o-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.normal-1x {
  visibility: visible !important;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.normal-2x {
  visibility: visible !important;
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.normal-3x {
  visibility: visible !important;
  -webkit-animation-duration: 2.5s;
  -moz-animation-duration: 2.5s;
  -o-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.tfx-scaleDown {
  -webkit-animation: scaleDown 0.7s ease both;
  -moz-animation: scaleDown 0.7s ease both;
  animation: scaleDown 0.7s ease both;
}

.tfx-scaleUp {
  -webkit-animation: scaleUp 0.7s ease both;
  -moz-animation: scaleUp 0.7s ease both;
  animation: scaleUp 0.7s ease both;
}

.tfx-scaleUpDown {
  -webkit-animation: scaleUpDown 0.5s ease both;
  -moz-animation: scaleUpDown 0.5s ease both;
  animation: scaleUpDown 0.5s ease both;
}

.tfx-scaleDownUp {
  -webkit-animation: scaleDownUp 0.5s ease both;
  -moz-animation: scaleDownUp 0.5s ease both;
  animation: scaleDownUp 0.5s ease both;
}

.tfx-scaleDownCenter {
  -webkit-animation: scaleDownCenter 0.4s ease-in both;
  -moz-animation: scaleDownCenter 0.4s ease-in both;
  animation: scaleDownCenter 0.4s ease-in both;
}

.tfx-scaleUpCenter {
  -webkit-animation: scaleUpCenter 0.4s ease-out both;
  -moz-animation: scaleUpCenter 0.4s ease-out both;
  animation: scaleUpCenter 0.4s ease-out both;
}

@-webkit-keyframes scaleDown {
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
  }
}

@-moz-keyframes scaleDown {
  to {
    opacity: 0;
    -moz-transform: scale(0.8);
  }
}

@keyframes scaleDown {
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}

@-webkit-keyframes scaleUp {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
  }
}

@-moz-keyframes scaleUp {
  from {
    opacity: 0;
    -moz-transform: scale(0.8);
  }
}

@keyframes scaleUp {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
}

@-webkit-keyframes scaleUpDown {
  from {
    opacity: 0;
    -webkit-transform: scale(1.2);
  }
}

@-moz-keyframes scaleUpDown {
  from {
    opacity: 0;
    -moz-transform: scale(1.2);
  }
}

@keyframes scaleUpDown {
  from {
    opacity: 0;
    transform: scale(1.2);
  }
}

@-webkit-keyframes scaleDownUp {
  to {
    opacity: 0;
    -webkit-transform: scale(1.2);
  }
}

@-moz-keyframes scaleDownUp {
  to {
    opacity: 0;
    -moz-transform: scale(1.2);
  }
}

@keyframes scaleDownUp {
  to {
    opacity: 0;
    transform: scale(1.2);
  }
}

@-webkit-keyframes scaleDownCenter {
  to {
    opacity: 0;
    -webkit-transform: scale(0.7);
  }
}

@-moz-keyframes scaleDownCenter {
  to {
    opacity: 0;
    -moz-transform: scale(0.7);
  }
}

@keyframes scaleDownCenter {
  to {
    opacity: 0;
    transform: scale(0.7);
  }
}

@-webkit-keyframes scaleUpCenter {
  from {
    opacity: 0;
    -webkit-transform: scale(0.7);
  }
}

@-moz-keyframes scaleUpCenter {
  from {
    opacity: 0;
    -moz-transform: scale(0.7);
  }
}

@keyframes scaleUpCenter {
  from {
    opacity: 0;
    transform: scale(0.7);
  }
}

@-webkit-keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }
  15% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 0;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: 29%;
  }
}

@-moz-keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }
  15% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 0;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: 29%;
  }
}

@keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }
  15% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 0;
    top: 50%;
  }
  100% {
    opacity: 0;
    top: 29%;
  }
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

@-moz-keyframes adv-loader-alt {
  0% {
    transform: rotate(-135deg);
    opacity: 0;
  }
  10% {
    transform: rotate(-135deg);
    opacity: 1;
  }
  30% {
    transform: rotate(-50deg);
  }
  40% {
    transform: rotate(-70deg);
  }
  60% {
    transform: rotate(35deg);
  }
  70% {
    transform: rotate(30deg);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 0.5);
  }
  90% {
    transform: rotate(135deg);
    opacity: 1;
  }
  100% {
    transform: rotate(135deg);
    opacity: 0;
  }
}

@-webkit-keyframes adv-loader-alt {
  0% {
    transform: rotate(-135deg);
    opacity: 0;
  }
  10% {
    transform: rotate(-135deg);
    opacity: 1;
  }
  30% {
    transform: rotate(-50deg);
  }
  40% {
    transform: rotate(-70deg);
  }
  60% {
    transform: rotate(35deg);
  }
  70% {
    transform: rotate(30deg);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 0.5);
  }
  90% {
    transform: rotate(135deg);
    opacity: 1;
  }
  100% {
    transform: rotate(135deg);
    opacity: 0;
  }
}

@keyframes adv-loader-alt {
  0% {
    transform: rotate(-135deg);
    opacity: 0;
  }
  10% {
    transform: rotate(-135deg);
    opacity: 1;
  }
  30% {
    transform: rotate(-50deg);
  }
  40% {
    transform: rotate(-70deg);
  }
  60% {
    transform: rotate(35deg);
  }
  70% {
    transform: rotate(30deg);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 0.5);
  }
  90% {
    transform: rotate(135deg);
    opacity: 1;
  }
  100% {
    transform: rotate(135deg);
    opacity: 0;
  }
}

.dataTables_wrapper table thead:last-child {
  display: none;
}

.dataTables_wrapper table thead tr th:nth-last-child(1) i {
  display: none;
}

.notification-delete {
  cursor: pointer;
}

/* Margin Styles*/
.m-0 {
  margin: 0 !important;
}

.mx-auto {
  margin: 0 auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 2rem !important;
}

.gap-1 {
  gap: 1rem
}

.mb-05 {
  margin-bottom: 0.5rem !important;
}

/*Button Styles*/
.btn-display {
  width: 100%;
  margin: auto;
  padding: 0.5rem 0.7rem;
  border-radius: 3px !important;
  cursor: default;
  background: #bdbdbd;
  color: #fff;
  display: inline-block;
  text-align: center;
  min-width: 70px;
  line-height: 1.25;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  font-size: 0.7rem;
  font-weight: 500;
  outline: 0 !important;
  word-wrap: break-word;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-manage {
  width: auto;
  margin: auto 10px 10px auto;
  /* margin: auto !important; */
  padding: 0.5rem 0.7rem;
  border-radius: 25px !important;
  cursor: pointer;
  background: #bdbdbd;
  color: #fff;
  display: inline-block;
  text-align: center;
  min-width: 70px;
  line-height: 1.25;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  font-size: 0.7rem;
  font-weight: 500;
  outline: 0 !important;
  word-wrap: break-word;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-slim {
  padding: 6px 20px !important;
}

/*Icon Styles*/
.icon-danger {
  color: #d9534f !important;
}

.icon-success {
  color: #5cb85c !important;
}

.icon-warning {
  color: #f0ad4e !important;
}

/*Icon Styles*/
.btn-danger {
  background-color: #d9534f !important;
}

.btn-success {
  background-color: #5cb85c !important;
  color: #fff !important;
}

.btn-warning {
  background-color: #f0ad4e !important;
  color: #fff !important;
}

.btn-info {
  background-color: #713fff !important;
  color: #fff !important;
}

/* Flex */
.flex-row {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
}

/* Border Styles */
.border-color-left {
  border-left: 1px solid rgba(0, 0, 0, 0.19);
}

/* Form Error Styles */
#first_name-msg,
#last_name-msg,
#personal_phone-msg,
#personal_email-msg,
#password-msg {
  margin-left: 0 !important;
  text-align: left;
  width: 100%;
  display: block;
  font-size: 12px;
}

.input-msg {
  margin-left: 0 !important;
  text-align: left;
  width: 100%;
  display: block;
  font-size: 12px;
}

.table-bordered th {
  text-align: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.text-center {
  text-align: center;
}
.center-text {
  text-align: center;
}
.d-flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.aligm-items-center {
  align-items: center;
}
.aligm-items-end {
  align-items: flex-end;
}
.aligm-items-start {
  align-items: flex-start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
.float-right {
  float: right;
}

.centralize-table-items {
  tbody {
    tr {
      td {
        text-align: center;
      }
    }
  }
}

.space-items {
  display: flex;
  justify-content: space-evenly;
}

.togglePasswordButton {
  font-style: normal;
  cursor: pointer;
  color: #713fff !important;
}

.cursor-pointer {
  cursor: pointer;
}
.embed-container {
  width: 100%;
  height: 53vmax;
}
.currency-badges {
  flex-direction: row;
  display: flex;
  > div {
    height: 32px;
    width: 32px;
    font-size: 1rem;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--colorWhite);
    background-color: #713fff;
    transform: translateX(-0.35rem);

    &:first-child {
      background-color: #713fff;
      transform: translateX(0);
    }
  }
}

.disburseSummary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
}

@media (max-width: 768px) {
  .disburseSummary {
    grid-template-columns: 1fr;
  }
}

.ant-input-outlined:focus, :where(.css-dev-only-do-not-override-d2lrxs).ant-input-outlined:focus-within {
  border-color: #713fff !important;
}

.ant-input-outlined:focus, :where(.css-dev-only-do-not-override-d2lrxs).ant-input-outlined:focus-within {
  border-color: #713fff !important;
}

.ant-input-outlined:hover {
  border-color: #713fff !important;
}

.ant-form-item-label > label {
  font-weight: 500;
  color: #262626;
}

.ant-input,
.ant-select-selector {
  border-radius: 8px !important;
}

.ant-input:hover,
.ant-select-selector:hover {
  border-color: #713fff !important;
  box-shadow: 0 0 0 2px rgba(113,63,255,0.1) !important;
}

.ant-input:focus,
.ant-select-selector:focus {
  border-color: #713fff !important;
  box-shadow: 0 0 0 2px rgba(113,63,255,0.2) !important;
}

.ant-select-focused .ant-select-selector {
  border-color: #713fff !important;
  box-shadow: 0 0 0 2px rgba(113,63,255,0.2) !important;
}

.ant-card:hover {
  box-shadow: 0 4px 12px rgba(113,63,255,0.15) !important;
}

.ant-upload-drag {
  border-radius: 8px !important;
  border: 2px dashed #d9d9d9 !important;
}

.ant-upload-drag:hover {
  border-color: #713fff !important;
}

.ant-btn-primary:hover {
  background: linear-gradient(to right, #8b6fff, #713fff) !important;
  box-shadow: 0 4px 12px rgba(113,63,255,0.45) !important;
}

.ant-form-item {
  margin-bottom: 24px;
}

