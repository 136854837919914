.LabelSubText {
  font-size: 0.75rem;
  color: #a0a0a0;
}

.errorMessage {
  font-size: 0.875rem;
  color: red;
}

.Title {
  font-weight: 800;
}
