.createInvestmentProduct {
  & .productBasicDetails {
    padding: 0;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  & .divider {
    width: 100%;
    margin-left: 10px;
    margin-top: 30px;
    display: inline-block;
  }

  & .financialsContainer {
    width: 100%;

    & > .noCurrencyErrorContainer {
      width: 100%;
      text-align: center;
    }
  }
}
