.statistics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  margin-top: 2.375rem;

  .statCard {
    border-radius: 0.625rem;
    border: 1px solid #f0f0f0;
    background: #fff;
    padding: 1rem;

    p {
      color: #8b8699;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0.025rem;
    }

    h1 {
      color: #200865;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
      letter-spacing: 0.0125rem;
    }
  }
}
