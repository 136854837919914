// Radio button to switch signature choice
.radiobuttongroup {
  display: flex;
  gap: 1rem;

  & input[type="radio"] {
    display: none;
  }
}

.radiobutton {
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #d1cfd6;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: #fff;
  color: #170d33;
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
}

.active {
  background-color: #fff;
  color: #170d33;
  border-color: #713fff;
}

.radiobutton:not(.active) {
  background-color: #fff;
  color: #170d33;
  border-color: #d1cfd6;
  cursor: pointer;
}

.signatureModaltitle {
  color: #200865;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

//signature pad styling

.signaturecontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signaturecanvas {
  width: 100%;
  height: 300px;
  margin-bottom: 20px;
  border-radius: 0.5rem;
  border: 0.5px solid #d1cfd6;
  background: #fff;
}

.signaturebutton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .signaturebuttonclear {
    border: 0;
    color: #713fff;
    background-color: #fff;
  }
}
.signaturebuttonsave {
  border: 1px solid #713fff;
  background-color: #713fff;
  color: #fff;
  border-radius: 0.5rem;
  padding: 0.65625rem 0.9375rem;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

//image upload for signature

.dropzone {
  margin-top: 1.5rem;
  padding: 20px;
  text-align: center;
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 0.625rem;
  border: 1px dashed #d7dce0;
  background: #f8f5ff;
}

// .active {
//   border-color: #200865;
//   background-color: #e0f2fe;
// }

.preview {
  margin-top: 20px;
}
.documentUploadHeading {
  font-size: 0.875rem;
  font-weight: 700;
  color: #170d33;
  letter-spacing: 0.00175rem;
  line-height: 1rem;
  margin-top: 0.5rem;
}
.documentUploadParagraph {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  color: #8b8699;
  span {
    color: #713fff;
  }
}
.documentUploadSmall {
  color: #8b8699;
  text-align: center;
  font-size: 0.625rem;
  font-style: italic;
  line-height: 1rem;
  letter-spacing: 0.025rem;
}

.previewImage {
  width: 500px;
  height: auto;
  border-radius: 10px;
}
