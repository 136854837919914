.CreditScoreContainer {
  height: 40rem;
  padding: 1rem;
  overflow-y: scroll;

  margin-bottom: 5rem;

  .CreditResultContainer {
    margin-top: 1.5rem;
  }

  .CreditDecision {
    margin-top: 2rem;
    span {
      font-weight: 700;
      display: inline-block;
      padding: 0 1rem;
      border-radius: 4px;

      &.Approved {
        color: #27be63;
        border: 1px solid #27be63;
        background-color: #eafbf1;
      }

      &.Rejected {
        color: #ff193b;
        border: 1px solid #ff193b;
        background-color: #fce9e9;
      }
    }
  }
}

.tenantFieldStyles {
  height: 2.5rem;
  padding-left: 1rem;
  border-radius: 10px;
  border: 1px solid #d7dce0;
}

.rules {
  margin-top: 10px;
}
