.Recovery input.error{

    border: 1px solid crimson !important;
    color: crimson;
}


#email-msg{
    margin-left:13px;
    text-align: left;
    width: 100%;
    display: block;
}


#slotOne-msg,#slotTwo-msg,#slotThree-msg,#slotFour-msg,#slotFive-msg,#slotSix-msg{
    margin: 0 0 0 -7px;
    text-align: center !important;
    width: 100%;
    display: block;
}

