.Header {
  margin-bottom: 3rem;
}

.errorMessage {
  font-size: 0.875rem;
  color: red;
}

.ActionContainer {
  display: flex;
  justify-content: end;

  .FilterOptions {
    display: flex;

    .FormGroup {
      position: relative;

      .SearchInput {
        border: 1px solid #d7dce0;
        width: 18rem;
        height: 2.6rem;
        border-radius: 4px;
        padding: 0 1rem;
        color: #677684;
        margin-right: 1rem;
      }

      .Icon {
        position: absolute;
        right: 2rem;
        top: 0.8rem;
        color: #677684;
      }
    }

    .FormButtonContainer {
      .SearchActionButton {
        height: 2.6rem;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .ActionContainer {
    flex-direction: column;

    .FilterOptions {
      display: flex;
      justify-content: space-between;

      .FormButtonContainer {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media only screen and (max-width: 630px) {
  .ActionContainer {
    .FilterOptions {
      display: flex;
      flex-direction: column;

      .FormGroup {
        .SearchInput {
          width: 100%;
        }

        .Icon {
          position: absolute;
          right: 2rem;
          top: 0.8rem;
          color: #677684;
        }
      }
    }
  }
}
