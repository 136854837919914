.no-line-height{
    line-height: 0;
    height: 1.8rem;
}

.notificationBtn{
    position: absolute;
    top: 12px;
    right: 300px;
    z-index: 100;
}

.underline{
    text-decoration: underline;
}

.row-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
