.SideBar {
  z-index: 100;
  width: 300px;
  height: 95vh;
  position: fixed;
  top: 4rem;
  left: -300px;
  bottom: 0;
  display: block;
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition-delay: 1s;
  -webkit-transition: all 0.4s ease;
  transition: all 0.6s ease;
  flex: 1 1 30%;
  overflow-y: scroll;
  scrollbar-width: none !important;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05) !important;
}

.SideBar::-webkit-scrollbar {
  display: none;
}

.SideBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.SideBar.show {
  left: 0;
}

.SideBar .side-nav-links {
  list-style: none;
  padding: 0.1em !important;
}

.SideBar .side-nav-links div {
  text-decoration: none;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.04); */
  color: #121212;
  background: #fff;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.SideBar .side-nav-links .sub-menu-visible {
  display: none;
}

.sub-menu-item .link-text {
  margin-left: 4rem;
  font-size: 0.8rem;
}

.sub-menu-item .link-text:hover {
  color: #713fff;
}

.sub-menu-item {
  padding-bottom: 0.5rem;
}

.sub-menu-item span {
  color: #3a434b;
  font-size: 0.75rem;
}

.SideBar .side-nav-links .parent-menu {
  position: relative;
  border-left: 0.25rem solid #fff;
  padding-left: 2rem;
}

.SideBar .side-nav-links .parent-menu .arrow-icon {
  position: absolute;
  right: 1.5rem;
  bottom: 1rem;
}

.SideBar .side-nav-links .parent-menu:hover {
  background: #e3d9ff;
  color: #713fff !important;
  border-left: 0.25rem solid #713fff;
  cursor: pointer;
}

.SideBar .side-nav-links li.active {
  background: #5cb85c;
  color: #fff;
  cursor: pointer;
}

.SideBar .side-nav-links li a i,
.SideBar .side-nav-links li div i {
  color: #121212;
}

/* .SideBar .side-nav-links li:hover a i,
.SideBar .side-nav-links li:hover div i {
  color: #713fff !important;
} */

.SideBar .side-nav-links li.active a i,
.SideBar .side-nav-links li.active div i {
  color: #fff !important;
}

.SideBar .side-nav-links li a,
.SideBar .side-nav-links li div {
  padding: 0.8em;
  width: 100%;
  display: block;
  text-align: left;
}

/* .SideBar .side-nav-links li:hover a,
.SideBar .side-nav-links li:hover div {
  color: #713fff !important;
} */

.SideBar .side-nav-links li .link-icon {
  position: absolute;
  top: 0.8rem;
}

.SideBar .side-nav-links li .menu-title {
  display: inline-block;
  margin-left: 1.5rem;
}

.SideBar .sidenav {
  position: absolute;
  top: 45px;
  padding: 12px;
  cursor: pointer;
  z-index: 100;
  font-size: 28px;
}

.SideBar .closeBtn {
  right: 0;
  color: #713fff;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.SideBar .closeBtn:hover {
  color: crimson !important;
  cursor: pointer !important;
}

.SideBar .card {
  max-width: 300px;
  margin: auto;
  text-align: center;
  border-radius: 0;
  border-right: none;
  border-left: none;
  border-top: none;
  display: inline-block;
  width: 100%;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05) !important;
}

.SideBar .title {
  color: grey;
  font-size: 14px;
  font-weight: lighter;
}

.card-bg {
  background: #fff;
  border-bottom: 4px solid #fff !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
  margin-top: 51px !important;
}

@media only screen and (max-width: 768px) {
  .SideBar {
    width: 100vw;
    left: -100vw;
  }

  .SideBar .card {
    width: 100%;
  }

  .SideBar .card {
    max-width: 100% !important;
  }

  .section-view {
    height: auto;
    display: block;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media only screen and (max-width: 450px) {
  .SideBar {
    width: 100vw;
    left: -100vw;
  }

  .SideBar .card {
    max-width: 100% !important;
  }
}

.sidebar-header {
  margin: auto;
  display: inline-block;
  width: 100%;
}

.sidebar-header .user-pic {
  float: left;
  width: 60px;
  padding: 2px;
  border-radius: 12px;
  margin-right: 15px;
  overflow: hidden;
}

.sidebar-header .user-pic img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

img {
  vertical-align: middle;
  border-style: none;
}

.sidebar-header .user-info {
  color: #121212;
}

.sidebar-header .user-info {
  float: left;
}

.sidebar-header .user-info .user-role {
  font-size: 12px;
}

.sidebar-header .user-info .user-role,
.sidebar-header .user-info .user-status {
  color: #121212;
}

.sidebar-header .user-info .user-status {
  font-size: 11px;
  margin-top: 4px;
  float: left;
  text-align: left;
}

.sidebar-header .user-info > span {
  display: flex;
  text-transform: capitalize;
  color: #121212;
}

.sidebar-header .user-info > span > ul {
  list-style: none;
  text-align: left !important;
  padding-left: 5px;
}

.sidebar-header .user-info .user-status i {
  font-size: 8px;
  margin-right: 4px;
  color: #5cb85c;
}

.logout {
  display: flex;
  justify-content: center;
}
