.investmentProspectComments {
  margin: 20px;
  font-family: 'Arial', sans-serif;
}

.commentCard {
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
}

.commentContent {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.commentFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commentAuthor{
  display: flex;
  align-items: center;
}

.authorInitial {
  background-color: #e6e6ff;
  border-radius: 50%;
  color: #666;
  //padding: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  text-align: center;
  margin-right: 10px;
}

.authorName {
  color: #333;
}

.commentTimestamp {
  color: #666;
  font-size: 0.8em;
}
