.HeaderTitle {
  color: #170d33;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.Details {
  border-radius: 0.625rem;
  background: var(--colorAdvanclyCultured);
  min-height: 12rem;
  padding: 1rem 2.5rem;
  margin-bottom: 1.5rem;
  width: 36.125rem;
  margin-bottom: 1.8125rem;

  ul {
    li {
      margin-bottom: 2rem;
      list-style-image: url("../../../../assets/svg/icons/tick-circle.svg");

      .DetailItem {
        display: flex;
        justify-content: space-between;
        padding-right: 10rem;

        span {
          justify-content: left;
        }
      }
    }
  }
}
