.filterContainer {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0.5rem 0 0;

  .filterItem {
    position: relative;
    width: 10rem;

    .FilterInput {
      border: 1px solid var(--colorMidBlue);
      width: 10rem;
      height: 2.7rem;
      border-radius: 4px;
      padding: 0 1rem;
      color: #677684;
      font-size: 0.875rem;
      margin-right: 1rem;
    }

    .Icon {
      position: absolute;
      right: 0.8rem;
      top: 0.8rem;
      color: #677684;
    }
  }
}
