.alignGraphTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #0d0f11;

  .leftSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h3 {
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
    }
  }

  .rightSection {
    margin: 0px;

    select {
      padding: 0px !important;
    }
  }
}
