.principalorinterestBody {
  padding: 1rem 1rem;

  .action {
    border: 1px solid var(--colorMidGrey);
    border-radius: 4px;
    padding: 1.3rem 1rem;
    background-color: #fff;
    margin-bottom: 1.5rem;
    height: 8rem;
    transition: border 0.3s ease-in, background-color 0.3s ease-in;

    label {
      color: var(--cardBorderColor);
    }

    .rolloverTypeText {
      padding-left: 1.8rem;
      color: #3a434b;
    }

    .principalRadio {
      margin-right: 1rem;
      accent-color: var(--colorBlue);
      cursor: pointer;
    }
  }

  .highlightedAction {
    border: 1px solid var(--colorBlue);
    background-color: var(--colorMidLightBlue);

    label {
      color: var(--colorBlue);
    }

    .principalRadio {
      margin-right: 1rem;
      accent-color: var(--colorBlue);
    }
  }
}
