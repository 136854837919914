.commentsContainer {
  padding-right: 2rem;

  .commentsSection {
    height: 10rem;
    overflow-y: scroll;

    .existingComments {
      & > p:first-of-type {
        font-weight: 900;

        & > span {
          color: rgb(154, 149, 149);
          font-weight: 100;
          margin-left: 0.5rem;
          font-size: 0.8rem;
        }
      }

      .comments {
        padding-bottom: 1rem;
        border-bottom: 2px solid #d7dce0;
      }
    }
  }
}
