.CardContainer {
  .Card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
    height: 20rem;
    overflow-y: scroll;
    padding: 1rem 0.5rem;

    .Title {
      padding-left: 1.3rem;
      color: #8b8699;
    }
  }
}
