.backButton {
  margin: 0;
  padding: 0;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  height: clamp(20px, 2.5vw, 24px);
  width: clamp(20px, 2.5vw, 24px);
  line-height: 28px;
  color: #200865;

  svg {
    height: 100%;
    width: 100%;
    color: #200865;
    fill: #200865 !important;
  }
}
