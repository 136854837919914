.AccordionContainer {
  width: 100%;
  border: 1px solid #d7dce0;
  border-radius: 5px;

  .settingsCard {
    display: flex;
    justify-content: space-between;
    height: 3.4rem;
    // align-items: center;
    // background: var(--colorWhite);
    // padding: 0.5rem 0.75rem;

    .title {
      font-weight: 400;
      font-size: 1rem;
      line-height: 24px;
      letter-spacing: 0.25px;
      color: #0d0f11;
      margin-left: 1rem;
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .ArrowUp {
      content: url("../../../../../assets/svg/icons/arrow-up.svg");
    }

    .ArrowRight {
      content: url("../../../../../assets/svg/icons/arrow-right.svg");
    }

    .ArrowUp,
    .ArrowRight {
      margin-right: 1rem;
      margin-top: 1rem;
      cursor: pointer;
    }
  }

  .TopBorder {
    border-top: 1px solid #d7dce0;
  }
}
