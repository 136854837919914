.InputGroup {
  width: 100%;
  padding: 6px 10px;
}

.InputGroup input {
  width: 100%;
  padding: 6px 10px;
}

.InputGroup label {
  width: 100%;
  padding: 6px 10px;
}

.SmallInput {
  border-radius: 8px;
  padding: 0.3rem 1rem 0.3rem 2rem;
  font-size: 0.9rem;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.19);
  font-weight: 400;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.05);
  background-image: none;
  outline: none;
  font: inherit;
  margin: 0.4rem 0 1rem 0;
  touch-action: manipulation;
  box-sizing: border-box;
  height: auto;
}
