.Modal__dialog.xlg-modal {
  max-width: 1000px;
  min-width: 1200px;
}

@media (min-width: 576px) {
  .Modal__dialog.lg-modal {
    height: 90vh;
  }
}
