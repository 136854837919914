.Disbursement .dataTables_wrapper table th,
.Disbursement .dataTables_wrapper table td {
  text-align: center;
}

.Disbursement .dataTables_wrapper table thead tr {
}

.Disbursement .dataTables_wrapper table thead tr th {
  font-weight: 500;
  font-size: 0.755rem;
  vertical-align: middle;
}

.Disbursement .dataTables_wrapper table td {
  vertical-align: middle;
  font-size: 0.725rem;
}

.Disbursement .dataTables_wrapper table td .manage-options {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Disbursement .dataTables_wrapper table td .manage-options .btn-manage {
  width: 75px;
  margin: auto 5px !important;
}

.Disbursement
  .dataTables_wrapper
  table
  td
  .manage-options
  .btn-manage.btn-slim {
  padding: 6px 10px !important;
}

.Disbursement .dataTables_wrapper table td .manage-options .btn-manage {
  width: 75px;
}

.Disbursement .dataTables_wrapper table thead tr th:nth-child(1) i {
  display: none;
}

/* .ManageIssues .dataTables_wrapper table td {
    max-width: 450px;
    vertical-align: middle;
    
    line-height: 1.7;
    letter-spacing: 0.025rem;
} */

.Disbursement .rounded-border {
  padding: 5px 10px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  line-height: 28px;
}

.Disbursement .flex-row {
  flex-wrap: wrap;
}

.table-fetch-select {
  border-radius: 8px;
  padding: 6px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #ced4da;
  color: #495057;
}
