.investmentTitle {
  color: #677684;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
}
.investmentSubTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #0d0f11;
}
.portfolio {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #0d0f11;
  margin-top: 40px;
}
.rightBorder {
  border-right: 1px solid #d7dce0;
  .viewIcon:hover {
    cursor: pointer;
  }
}
.investmentLayoutCard {
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #d7dce0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 2rem;
  padding: 1.5rem;
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 8px;
    color: #24292e;
  }
  .content {
    padding-bottom: 1.5rem;
    color: #677684;
  }
  .link {
    padding-top: 1.5rem;
    color: #2c6eec;
  }
  .link:hover {
    text-decoration: underline;
  }
  .percent {
    color: #2c6eec;
  }
}
.investmentLayoutCard:hover {
  border: 1px solid #2c6eec;
}
