.Container {
  width: 100%;
  padding: 0;

  .Body {
    padding: 0 1.5rem;
  }

  .FormContainer {
    .Form {
      .BioData {
        margin-bottom: 2rem;
      }

      .BioData > span:first-child {
        display: block;
      }

      .BioData > span:nth-child(2),
      .Description {
        color: #3a434b;
      }

      .FormHeader {
        border-bottom: 1px solid #d7dce0;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        & > h3 {
          font-weight: 600;
          margin-bottom: 1rem;
          color: #0d0f11;
        }
      }

      .FormButtonContainer {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1.5rem;
      }
    }
  }
}
