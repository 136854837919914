#toast {
    visibility: hidden;  
    min-width: 250px;  
    margin-left: -125px;  
    color: #fff;  
    text-align: center;  
    border-radius: 2px;  
    padding: .6rem .8rem;  
    position: fixed;  
    z-index: 100;  
    left: 50%;  
    top: 50px;  
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

#toast.show {
    visibility: visible; 
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s;
    /* animation: fadein 0.5s, fadeout 0.5s 2.5s; */
}

@-webkit-keyframes fadein {
    from {top: 0; opacity: 0;} 
    to {top: 50px; opacity: 1;}
}

@keyframes fadein {
    from {top: 0; opacity: 0;}
    to {top: 50px; opacity: 1;}
}

@-webkit-keyframes fadeout {
    from {top: 50px; opacity: 1;} 
    to {top: 0; opacity: 0;}
}

@keyframes fadeout {
    from {top: 50px; opacity: 1;}
    to {top: 0; opacity: 0;}
}