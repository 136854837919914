.pagination {
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: center;

  .react-pagination-js-bootstrap ul {
    li {
      a {
        font-weight: bolder;
      }
    }
  }
}
