.Header {
  color: #200865;
  margin-bottom: 3rem;
}

.ActionContainer {
  display: flex;

  .SendMessage {
    margin-right: 2rem;

    & > h1 {
      font-size: 1.5rem;
      font-weight: 700;
      margin-top: 3rem;
      margin-bottom: 1rem;
    }

    .SendMessageIcon {
      display: inline-block;
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      background-image: url("../../assets/svg/icons/message-icon.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-color: #e3d9ff;
      background-size: 2rem 2rem;
      transition: background-color 0.3s ease-in;
    }
  }

  .PreviousMessage {
    & > h1 {
      font-size: 1.5rem;
      font-weight: 700;
      margin-top: 3rem;
      margin-bottom: 1rem;
    }

    .PreviousMessageIcon {
      display: inline-block;
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      background-image: url("../../assets/svg/icons/paper-icon.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-color: #e3d9ff;
      background-size: 2rem 2rem;
      transition: background-color 0.3s ease-in;
    }
  }

  .SendMessage,
  .PreviousMessage {
    padding: 2rem;
    border-radius: 10px;
    width: 23rem;
    height: 20rem;
    background-color: #ffffff;
    border: 1px solid #d7dce0;
    transition: border 0.3s ease-in, background-color 0.3s ease-in;
  }
  .SendMessage:hover,
  .PreviousMessage:hover {
    border: 1px solid #713fff;
    background-color: #e3d9ff;
    cursor: pointer;

    & .SendMessageIcon,
    .PreviousMessageIcon {
      background-color: #ffffff;
    }

    & > h1 {
      color: #200865;
    }
  }
}

@media only screen and (max-width: 600px) {
  .ActionContainer {
    display: flex;
    flex-direction: column;

    .SendMessage {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }
}
