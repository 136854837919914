.profileArea {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #d7dce0;
  border-bottom: 1px solid #d7dce0;
  margin-bottom: 1.5rem;

  .profileAreaCard {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    letter-spacing: 0.4px;
    padding: 1.5rem 0px;

    .title {
      color: #677684;
      font-weight: 600;
      padding-bottom: 1rem;
    }
    .content {
      color: #0d0f11;
    }
  }
}

.approve {
  color: #001540;

  &:hover {
    font-weight: bolder;
    color: #47ed47;
  }
}
.decline {
  color: #001540;
  &:hover {
    font-weight: bolder;

    color: #ff193b;
  }
}
