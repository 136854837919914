.container {
    width: 100%;
    display: flex;
    justify-content: center;

    .formContainer {
        width: 640px;

        .form {
            display: flex;
            justify-content: center;
            flex-direction: column;

            .textBox {
                margin: 20px 0;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .field {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    input {
        border: 1px solid #d7dce0;
        border-radius: 4px;
        padding: 5px;

        &::placeholder {
            color: #a1a2a3;
        }
    }
}