.VersionContainer {
  width: 100%;
}

.Header {
  color: #0e347e;
  margin-bottom: 3rem;
}

.FormContainer {
  display: flex;
  justify-content: center;

  .Form {
    padding: 1.5rem;
    border: 1px solid #d7dce0;
    border-radius: 4px;
    width: 44rem;
    height: 38rem;

    .FormLabel {
      display: flex;
      justify-content: space-between;
      color: #8b8699;
    }

    .FormHeader {
      border-bottom: 1px solid #d7dce0;
      display: flex;
      justify-content: space-between;

      & > h3 {
        font-weight: 600;
        margin-bottom: 1rem;
        color: #0d0f11;
      }
    }

    .Instructions {
      color: #3a434b;
      font-size: 1rem;
      margin: 1.5rem 0;
    }

    .Input {
      border: 1px solid #d7dce0;
      width: 100%;
      height: 3rem;
      border-radius: 4px;
      padding: 0 1rem;
      color: #677684;
    }

    .Input::placeholder {
      color: #677684;
    }

    .FormButtonContainer {
      display: flex;
      justify-content: flex-end;
    }

    .errorMessage {
      font-size: 0.875rem;
      color: red;
    }
  }
}

@media only screen and (max-width: 650px) {
  .FormContainer {
    .Form {
      height: 40rem;
      width: 30rem;
    }
  }
}
