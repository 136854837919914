.modal_title {
  color: #200865;

  font-style: normal;

  font-weight: 600;

  line-height: normal;

  font-size: 20px;

  margin-bottom: 20px;
}

.table {
  width: 100%;

  border-collapse: collapse;

  th,
  td {
    border: 1px solid rgb(218, 218, 218);

    padding: 8px;

    text-align: left;
  }

  th {
    color: #170d33;

    font-style: normal;

    font-weight: 700;

    line-height: 150%;

    font-size: 14px;
  }

  td {
    color: #645e77;

    font-style: normal;

    font-weight: 400;

    line-height: 150%;

    font-size: 14px;

    &:last-of-type {
      text-align: center;

      .icon_button {
        display: flex;

        align-items: center;

        gap: 8px;
      }
    }

    &:nth-of-type(3) {
      text-align: center;

      .pendingStatus {
        border-radius: 40px;

        color: #f5a623;

        font-size: 12px;

        font-style: normal;

        font-weight: 400;

        line-height: 16px;

        background: #fef4e5;

        padding: 2px 10px;
      }
    }
  }
}
