.WalletDetails {
  .WithDrawFund {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
  }

  .Details {
    border-radius: 0.625rem;
    background: var(--colorAdvanclyCultured);
    min-height: 12rem;
    padding: 1rem 2.5rem;
    margin-bottom: 1.5rem;

    ul {
      li {
        margin-bottom: 2rem;
        list-style-image: url("../../../../../../assets/svg/icons/tick-circle.svg");

        .DetailItem {
          display: flex;

          span {
            display: block;
            width: 15rem;
          }

          .Refresh {
            font-size: 0.875rem;
            color: var(--colorBlue);
            cursor: pointer;

            &::before {
              content: url("../../../../../../assets/svg/icons/refresh-circle.svg");
              position: relative;
              top: 0.2rem;
              margin-right: 0.2rem;
            }
          }
        }
      }
    }
  }

  h5 {
    color: #8b8699;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
}
