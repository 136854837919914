.general {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;

  .details {
    h1 {
      color: #170d33;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.25rem;
      margin-bottom: 1rem;
    }

    .cardInformation {
      width: 100%;
      background-color: #f8f8f8;
      border-radius: 0.5rem;
      padding: 1.6875rem 1.0625rem;

      .cardItem {
        background-color: #ffffff;
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 0.25rem;
        margin-bottom: 0.5rem;
        height: 3.5rem;
        display: flex;
        justify-content: space-between;

        p {
          color: #170d33;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;

          &.value {
            color: #170d33;
            text-align: right;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.25rem;
          }
        }
      }
    }
  }
}
