.HeaderWithButton {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  margin: 0.5rem 0;
}

.HeaderWithButton .header__text {
  padding: 0.6rem 0;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 320.98px) {
  .HeaderWithButton .header__text {
    font-size: 15px;
    line-height: 1.2;
  }
}

@media (max-width: 575.98px) {
  .HeaderWithButton {
    flex-direction: column;
  }
  .HeaderWithButton .header__text {
    padding: 0.6rem 0;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.2;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .HeaderWithButton .header__text {
    font-size: 17px;
    padding: 0.6rem 0;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
