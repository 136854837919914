.DoubleInputContainer {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;

  & > div {
    width: 15rem;
  }
}

.InputContainer {
  width: 100%;
  padding: 0;
  margin: 0;
}
