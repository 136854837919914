.CustomBadge {
  background-color: var(--colorMidLightBlue);
  color: var(--colorBlue);
  padding: 0.25rem 0.5rem;
  height: 1.6rem;
  border-radius: 2.5rem;
  font-size: 0.75rem;
  margin-left: 1rem;
  display: inline-block;
}
