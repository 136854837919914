.Ownership {
  margin-bottom: 3rem;

  .Title {
    color: #713fff;
  }

  .ActionBar {
    display: flex;
    justify-content: flex-end;
    padding: 0 1rem;

    span {
      color: #713fff;
      cursor: pointer;
      text-decoration: underline;
      font-size: 0.875rem;
      font-weight: 700;
    }
  }
}
