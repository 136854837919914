.bankStatement_container {
    width: 100%;
    padding-top: 64px;
    @media (min-width: 960px) {
        padding-top: 0;
    }

    header {
        // margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        text-align: center;

        .search {
            padding-top: 0;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            .searchBox {
                border: 1px solid #000;
                display: flex;
                align-items: center;
                border-radius: 10px;
                padding: 0 10px;
                width: 250px;
                height: 50px;
                
                input {
                    border: none;
                    width: 100%;
                }
                img {
                    width: 18px;
                    height: 18px;
                }
            }

        }

        @media (min-width: 1189px) {
            text-align: center;
            position: relative;
    
            .search {
                padding-top: 0;
                position: absolute;
                right: 0;
                top: 0;

                .searchBox {
                    width: 400px;
                    height: 30px;

                    input {
                        width: 100%;
                    }
                    img {
                        
                    }
                }

            }
        }
    }

    .TableResponsive, .Table {
        border: none !important;
        
        
        .TableCell {
            cursor: pointer;
        }
    }

    .btn {
        margin-top: 20px;
        border: none;
        background-color: #713fff;
        color: #fff;
        border-radius: 10px;
        padding: 5px 10px;
        font-size: 16px;
        width: 100%;
        height: 50px;

        @media (min-width: 1280px) {
            width: 500px;
        }
    }
}