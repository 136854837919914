.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #200865;
  width: 100%;
  margin: 0;
  height: 64px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  color: #fff !important;
}
.navbar__hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fuse_white_logo {
  padding-left: 15px !important;
}
.user_class {
  padding-left: 14px;
}
.Header .navbar__account {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.dropdown-item:active {
  background-color: #713fff !important;
}

.loggedIn-user {
  margin: 0 1.5rem;
}

.loggedIn-user span {
  display: block;
}

.loggedIn-user span.loggedIn-user-name {
  font-size: 0.875rem;
  font-weight: 600;
}

.loggedIn-user span.loggedIn-user-role-menu {
  color: #81909d;
  margin-top: 0.25rem;
}

.loggedIn-user > hr {
  margin: 0.2rem 0;
  border: 1px solid #d7dce0;
}

/* .Header .navbar__logo {
  width: 200px;
} */

.Header .navbar__account .navbar__logout,
.Header .navbar__account .navbar__user,
.Header .navbar__account .navbar__signup,
.Header .navbar__account .navbar__login {
  display: inline-block;
  padding: 0 15px;
  vertical-align: top;
  box-sizing: border-box;
  line-height: 51px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .Header .navbar__account .navbar__logout {
    display: none;
  }

  .Header .navbar__account .navbar__user {
    display: none;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
