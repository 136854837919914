.AccordionContainer {
  width: 100%;

  .settingsCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    background: var(--colorWhite);
    border: 1px solid #d7dce0;
    border-radius: 4px;
    padding: 0.5rem 0.75rem;

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.25px;
      color: #0d0f11;
    }
  }

  .Content {
    width: 90%;
    margin-bottom: 1.5rem;
  }
}
