.FormGroup {
  position: relative;

  .SearchInput {
    border: 1px solid #d7dce0;
    width: 18rem;
    height: 2.6rem;
    border-radius: 4px;
    padding: 0 1rem;
    color: #677684;
    margin-right: 1rem;
  }

  .FilterInput {
    border: 1px solid #d7dce0;
    width: 8rem;
    height: 2.6rem;
    border-radius: 4px;
    padding: 0 1rem;
    color: #677684;
    font-size: 0.875rem;
    margin-right: 1rem;
  }

  .FilterMessageType {
    width: 10rem;
  }

  .Icon {
    position: absolute;
    right: 2rem;
    top: 0.8rem;
    color: #677684;
  }
}
