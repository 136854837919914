.Login input.error {
  border-bottom: 1px solid crimson !important;
  color: crimson;
}

/* new styling  for login */
.form-overview {
  margin-top: 112px;
}

.login-background-image img {
  width: 100%;
  height: 90vh;
  align-self: start;
}
.proceed-login {
  color: #3a434b;
  padding-top: 16px;
}
.form-overview-login-div label {
  display: block;
  color: #3a434b;
}
.form-overview-login-div input {
  width: 30rem;
  height: 3rem;
  border: 1px solid #bac2c9;
  border-radius: 4px;
}

.log-in-reset {
  display: flex;
  justify-content: space-between;
  padding-top: 68px;
}

/* End of new styling */

.header-title {
  font-size: 6rem;
  font-weight: 700;
  margin-bottom: 40px;
  font-stretch: extra-expanded;
}

.header-text {
  font-size: 2rem;
  color: #0074d9;
}

.login-header-margin {
  margin-top: -5px;
}

.side-login-padding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: transparent;
  box-shadow: 0 8rem 8rem rgba(0, 0, 0, 0.6);
}

.form {
  margin: 30px auto;
  width: 100%;
}

.form-group--1 {
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}

.form-group--2 {
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}

.form-group--3 {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  text-align: left;
}

.form-group--3 span {
  text-align: left;
}

.form-group--1 i {
  font-size: 20px;
  color: white;
  position: absolute;
  top: 15px;
  right: 0;
}

.form-group--2 i {
  font-size: 20px;
  color: white;
  position: absolute;
  top: 15px;
  right: 0;
}

.form-group__input {
  font-size: 1rem;
  font-family: inherit;
  color: white;
  padding: 0.8rem 1rem 0.8rem 0;
  background-color: #0074d9;
  border: none;
  border-bottom: 2px solid white;
  width: 100%;
  display: block;
  transition: all 0.3s;
}

.form-group__input:focus {
  background: #0074d9;
  border-bottom: 2px solid #9ce37d;
}

.form-group__input:focus ~ i {
  color: white !important;
}

.form-group__input::placeholder {
  color: white;
  padding: 0;
}

.btn-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.reset p {
  color: white;
  font-weight: 400;
}

.reset a {
  font-weight: 600;
}

.login-button {
  background: whitesmoke;
  border: none;
  width: 8rem;
  height: 2.5rem;
  border-radius: 1.5rem;
  cursor: pointer;
}

.login-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.login-button:active {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.text-block {
  float: left;
}

.text-block__header {
  color: white;
  font-size: 2.5rem;
  font-weight: 400;
  margin-top: -20px;
  margin-bottom: 15px;
  text-align: left;
}

.text-block__cta {
  text-align: left;
  font-size: 15px;
  color: white;
}
.login-flex {
  display: flex;
  justify-content: space-around !important;
  align-items: flex-start;
}

/* .sidebar-heading {
  height: 100vh;
  background: url("../../assets/images/nomad.jpg");
  background-size: cover;
  background-position: bottom;
} */

.loader-margin {
  margin-bottom: 30px;
}

/* .form-group--3 #email-msg,
.form-group--3 #password-msg {
  margin-left: 0 !important;
  text-align: left;
  font-size: 12px;
  width: 100%;
  display: block;
} */
/* 
@media (max-width: 1381px) {
 
}

@media (max-width: 1251px) {

}

@media (max-width: 1150px) {
 
}

@media (max-width: 1000px) {
 
} */

@media (max-width: 768px) {
  .login-flex .login-background-image {
    display: none;
  }
  .form-overview-login-div input {
    width: 100% !important;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .login-flex .login-background-image {
    display: none;
  }
}

@media (max-width: 320px) {
  .login-flex .login-background-image {
    display: none;
  }
}

/* @media (min-width: 1281px) {
  .login-flex .login-background-image {
    display: none;
  }
} */

/* @media (max-width: 1110px) {
  .login-flex .login-background-image {
    display: none;
  }
} */

/* @media (max-width: 985px) {
  .login-flex .login-background-image {
    display: none;
  }
} */

@media (max-width: 769px) {
  .login-flex .login-background-image {
    display: none;
  }
}

@media (max-width: 768px) {
  .login-flex .login-background-image {
    display: none;
  }
}

@media (max-width: 481px) {
  .login-flex .login-background-image {
    display: none;
  }
}

/* @media (min-width: 1025px) and (max-width: 1280px) {
  .login-flex .login-background-image {
    display: none;
  }
} */

@media (min-width: 768px) and (max-width: 978px) and (orientation: landscape) {
  .login-flex .login-background-image {
    display: none;
  }
}

@media (max-width: 978px) {
  .login-flex .login-background-image {
    display: none;
  }
}

@media (max-width: 767px) {
  .login-flex .login-background-image {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .login-flex .login-background-image {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .login-flex .login-background-image {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .login-flex .login-background-image {
    display: none;
  }
}

@media (max-width: 320px) {
  .login-flex .login-background-image {
    display: none;
  }
}
