.FormGroup {
  position: relative;

  .SearchInput {
    border: 1px solid #d7dce0;
    width: 15rem;
    height: 2.4rem;
    border-radius: 8px;
    padding: 0 1rem;
    color: #677684;
    margin-right: 1rem;
    margin-left: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  .Icon {
    position: absolute;
    right: 2rem;
    top: 0.8rem;
    color: #677684;
  }
}

.FormButtonContainer {
  margin-top: -0.9rem;

  .SearchActionButton {
    height: 2.4rem;
  }
}
