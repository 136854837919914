.grid__full,
.grid__half,
.grid__half__sticky,
.grid__three__sticky,
.grid__six__sticky,
.grid__three,
.grid__four,
.grid__four__sticky,
.grid__skillset,
.grid__testimonial,
.grid__services,
.grid__five,
.grid__six,
.grid__menu,
.grid__80,
.grid__results,
.grid__75,
.grid__70,
.grid__65,
.grid__60,
.grid__40,
.grid__35,
.grid__30,
.grid__25,
.grid__20,
.grid__filter,
.grid__client,
.grid__hotel__img,
.grid__hotel__desc,
.grid__hotel__pricing,
.grid__85,
.grid__15,
.grid__filter,
.grid__results,
.grid__psgform,
.grid__cservice,
.grid__title__sticky,
.grid__names__sticky,
.grid__20__sticky,
.grid__25__sticky,
.grid__three__sticky,
.grid__30__sticky,
.grid__40__sticky,
.grid__70__sticky {
  position: relative;
  float: left;
  height: auto;
  cursor: pointer;
}

.grid__padding {
  padding: 0 15px;
}
.padding__full {
  padding: 15px;
}

.grid__padding__right {
  padding: 0 15px 0 0;
}

.grid__padding__left {
  padding: 0 0 0 15px;
}

.grid__six__sticky {
  width: 16.66% !important;
}

.grid__title__sticky {
  width: 18% !important;
}

.grid__names__sticky {
  width: 27.33% !important;
}

.grid__20__sticky {
  width: 20% !important;
}

.grid__25__sticky {
  width: 25% !important;
}

.grid__half__sticky {
  width: 50% !important;
}

.grid__three__sticky {
  width: 33.33% !important;
}

.grid__four__sticky {
  width: 25% !important;
}

.grid__30__sticky {
  width: 30% !important;
}

.grid__40__sticky {
  width: 40% !important;
}

.grid__70__sticky {
  width: 70% !important;
}

.grid__three__sticky {
  width: 33.33%;
}

.grid__padding-xmobile {
  padding: 0 15px;
}

@media only screen and (max-width: 2100px) {
  .services-tab {
    width: 70%;
  }

  .grid__full {
    width: 100%;
  }

  .grid__half,
  .grid__hotel__desc {
    width: 50%;
  }

  .grid__three {
    width: 33.33%;
  }

  .grid__testimonial {
    width: 31.33%;
  }

  .grid__four,
  .grid__skillset,
  .pricing--item,
  .grid__services {
    width: 25%;
  }

  .grid__five {
    width: 20%;
  }

  .grid__six {
    width: 16.66%;
  }

  .grid__80,
  .grid__results {
    width: 80%;
  }

  .flight-result,
  .flight-filter,
  .hotel-result,
  .hotel-room,
  .hotel-filter,
  .package-result,
  .package-room,
  .package-filter,
  .slider-cta {
    margin-top: -80px;
  }

  .grid__70 {
    width: 70%;
  }

  .grid__75 {
    width: 75%;
  }

  .grid__65 {
    width: 65%;
  }

  .grid__25 {
    min-height: 20px;
  }

  .grid__60 {
    width: 60%;
  }

  .grid__40 {
    width: 40%;
  }

  .grid__35 {
    width: 35%;
  }

  .grid__30,
  .grid__hotel__img {
    width: 30%;
  }

  .grid__20,
  .grid__filter,
  .grid__hotel__pricing {
    width: 20%;
  }

  .grid__25 {
    width: 25%;
  }

  .grid__85 {
    width: 85%;
  }

  .grid__15 {
    width: 15%;
  }

  .grid__psgform {
    width: 73%;
  }

  .grid__cservice {
    width: 27%;
  }

  .left-mobile {
    border-left: none;
  }

  .grid__boxed {
    padding: 1em !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  .tfx-header a.tfx-logo {
    margin-left: 0;
    margin-top: 1px;
  }

  /* .tfx-icon {
    height: 100vh;
    width: auto;
    margin-top: auto;
  } */

  section,
  footer {
    padding: 70px 50px;
  }

  h1 {
    font-size: 36px;
  }

  .tab-wrap {
    width: 95%;
  }

  .tfx-services {
    height: 80vh;
    background-size: 100% 100%;
  }

  .tfx-header a.tfx-logo {
    background-size: 90%;
  }

  .pc-view {
    display: inline-block;
  }

  .mobile-view {
    display: none;
  }

  .contain-content {
    height: 80vh;
  }

  .center-container {
    height: 93vh;
  }

  .form-steps {
    margin-top: auto;
  }

  .login-form {
    margin-top: auto;
  }

  .tfx-user-form-wrapper {
    margin: 3em auto;
  }
}

@media only screen and (max-width: 1500px) {
  .services-tab {
    width: 70%;
  }

  .grid__full {
    width: 100%;
  }

  .grid__half,
  .grid__hotel__desc {
    width: 50%;
  }

  .grid__three {
    width: 33.33%;
  }

  .grid__testimonial {
    width: 31.33%;
  }

  .grid__four,
  .grid__skillset,
  .pricing--item,
  .grid__services {
    width: 25%;
  }

  .grid__five {
    width: 20%;
  }

  .grid__six {
    width: 16.66%;
  }

  .grid__80,
  .grid__results {
    width: 80%;
  }

  .flight-result,
  .flight-filter,
  .hotel-result,
  .hotel-room,
  .hotel-filter,
  .package-result,
  .package-room,
  .package-filter,
  .slider-cta {
    margin-top: -80px;
  }

  .grid__70 {
    width: 70%;
  }

  .grid__75 {
    width: 75%;
  }

  .grid__65 {
    width: 65%;
  }

  .grid__25 {
    min-height: 20px;
  }

  .grid__60 {
    width: 60%;
  }

  .grid__40 {
    width: 40%;
  }

  .grid__35 {
    width: 35%;
  }

  .grid__30,
  .grid__hotel__img {
    width: 30%;
  }

  .grid__20,
  .grid__filter,
  .grid__hotel__pricing {
    width: 20%;
  }

  .grid__25 {
    width: 25%;
  }

  .grid__85 {
    width: 85%;
  }

  .grid__15 {
    width: 15%;
  }

  .grid__psgform {
    width: 73%;
  }

  .grid__cservice {
    width: 27%;
  }

  .left-mobile {
    border-left: none;
  }

  .grid__boxed {
    padding: 1em !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  .tfx-header a.tfx-logo {
    margin-left: 0;
    margin-top: 1px;
  }

  /* .tfx-icon {
    height: 100vh;
    width: auto;
    margin-top: auto;
  } */

  section,
  footer {
    padding: 70px 50px;
  }

  h1 {
    font-size: 36px;
  }

  .tab-wrap {
    width: 95%;
  }

  .tfx-services {
    height: 80vh;
    background-size: 100% 100%;
  }

  .tfx-header a.tfx-logo {
    background-size: 90%;
  }

  .pc-view {
    display: inline-block;
  }

  .mobile-view {
    display: none;
  }

  .contain-content {
    height: 80vh;
  }

  .center-container {
    height: 93vh;
  }

  .form-steps {
    margin-top: auto;
  }

  .login-form {
    margin-top: auto;
  }

  .tfx-user-form-wrapper {
    margin: 3em auto;
  }
}

@media only screen and (max-width: 1366px) {
  .services-tab {
    width: 75%;
  }

  .grid__full {
    width: 100%;
  }

  .grid__half,
  .grid__hotel__desc {
    width: 50%;
  }

  .grid__three {
    width: 33.33%;
  }

  .grid__testimonial {
    width: 31.33%;
  }

  .grid__four,
  .grid__skillset,
  .pricing--item,
  .grid__services {
    width: 25%;
  }

  .grid__five {
    width: 20%;
  }

  .grid__six {
    width: 16.66%;
  }

  .grid__80,
  .grid__results {
    width: 80%;
  }

  .flight-result,
  .flight-filter,
  .hotel-result,
  .hotel-room,
  .hotel-filter,
  .package-result,
  .package-room,
  .package-filter,
  .slider-cta {
    margin-top: -80px;
  }

  .grid__70 {
    width: 70%;
  }

  .grid__75 {
    width: 75%;
  }

  .grid__65 {
    width: 65%;
  }

  .grid__25 {
    min-height: 20px;
  }

  .grid__60 {
    width: 60%;
  }

  .grid__40 {
    width: 40%;
  }

  .grid__35 {
    width: 35%;
  }

  .grid__30,
  .grid__hotel__img {
    width: 30%;
  }

  .grid__20,
  .grid__filter,
  .grid__hotel__pricing {
    width: 20%;
  }

  .grid__25 {
    width: 25%;
  }

  .grid__85 {
    width: 85%;
  }

  .grid__15 {
    width: 15%;
  }

  .grid__psgform {
    width: 73%;
  }

  .grid__cservice {
    width: 27%;
  }

  .left-mobile {
    border-left: none;
  }

  .grid__boxed {
    padding: 1em !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  .tfx-header a.tfx-logo {
    margin-left: 0;
    margin-top: 1px;
  }

  /* .tfx-icon {
    height: 100vh;
    width: auto;
    margin-top: auto;
  } */

  section,
  footer {
    padding: 70px 50px;
  }

  h1 {
    font-size: 36px;
  }

  .tab-wrap {
    width: 95%;
  }

  .tfx-services {
    height: 80vh;
    background-size: 100% 100%;
  }

  .tfx-header a.tfx-logo {
    background-size: 90%;
  }

  .pc-view {
    display: inline-block;
  }

  .mobile-view {
    display: none;
  }

  .contain-content {
    height: 80vh;
  }

  .center-container {
    height: 93vh;
  }

  .form-steps {
    margin-top: auto;
  }

  .login-form {
    margin-top: auto;
  }

  .tfx-user-form-wrapper {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1200px) {
  .services-tab {
    width: 85%;
  }

  .grid__full {
    width: 100%;
  }

  .grid__half,
  .grid__services,
  .grid__hotel__desc {
    width: 50%;
  }

  .grid__skillset,
  .grid__three {
    width: 33.33%;
  }

  .grid__testimonial {
    width: 31.33%;
  }

  .grid__four,
  .pricing--item {
    width: 25%;
  }

  .grid__five {
    width: 20%;
  }

  .grid__six {
    width: 16.66%;
  }

  .grid__80,
  .grid__results {
    width: 80%;
  }

  .grid__70 {
    width: 70%;
  }

  .grid__75 {
    width: 75%;
  }

  .left-mobile {
    border-left: none;
  }

  .contain-content {
    height: 87vh;
  }

  .center-container {
    height: 93vh;
  }

  .grid__65 {
    width: 65%;
  }

  .grid__25 {
    min-height: 20px;
  }

  .grid__60 {
    width: 60%;
  }

  .grid__40 {
    width: 40%;
  }

  .grid__35 {
    width: 35%;
  }

  .grid__30,
  .grid__hotel__img {
    width: 30%;
  }

  .grid-description {
    font-size: 1.1em;
  }

  h1 {
    font-size: 30px;
  }

  .tab-wrap {
    width: 95%;
  }

  .tfx-services {
    height: 80vh;
    background-size: cover;
  }

  .flight-result,
  .flight-filter,
  .hotel-result,
  .hotel-room,
  .hotel-filter,
  .package-result,
  .package-room,
  .package-filter,
  .slider-cta {
    margin-top: -80px;
  }

  .tfx-header a.tfx-logo {
    background-size: 90%;
  }

  .grid__20,
  .grid__filter,
  .grid__hotel__pricing {
    width: 20%;
  }

  .grid__25 {
    width: 25%;
  }

  .grid__boxed {
    padding: 1em !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  .tfx-header a.tfx-logo {
    margin-left: 0;
    margin-top: 1px;
  }

  /* .tfx-icon {
    height: 100vh;
    width: auto;
    margin-top: auto;
  } */

  section,
  footer {
    padding: 70px 30px;
  }

  .grid__85 {
    width: 85%;
  }

  .grid__15 {
    width: 15%;
  }

  .grid__psgform {
    width: 73%;
  }

  .grid__cservice {
    width: 27%;
  }

  .pc-view {
    display: inline-block;
  }

  .mobile-view {
    display: none;
  }

  .form-steps {
    margin-top: auto;
  }

  .login-form {
    margin-top: auto;
  }

  .tfx-user-form-wrapper {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1024px) {
  .services-tab {
    width: 95%;
  }

  .grid__full {
    width: 100%;
  }

  .grid__half,
  .grid__services,
  .grid__hotel__desc {
    width: 50%;
  }

  .grid__skillset,
  .grid__three {
    width: 33.33%;
  }

  .grid__testimonial {
    width: 31.33%;
  }

  .grid__four,
  .pricing--item {
    width: 25%;
  }

  .grid__five {
    width: 20%;
  }

  .grid__six {
    width: 16.66%;
  }

  .grid__80,
  .grid__results {
    width: 80%;
  }

  .grid__70 {
    width: 70%;
  }

  .grid__75 {
    width: 75%;
  }

  .contain-content {
    height: 80vh;
  }

  .center-container {
    height: 93vh;
  }

  .grid__65 {
    width: 65%;
  }

  .grid__25 {
    min-height: 20px;
  }

  .grid__60 {
    width: 60%;
  }

  .grid__40 {
    width: 40%;
  }

  .grid__35 {
    width: 35%;
  }

  .grid__30,
  .grid__hotel__img {
    width: 30%;
  }

  .tfx-header a.tfx-logo {
    margin-left: 0;
    margin-top: 1px;
  }

  /* .tfx-icon {
    height: 100vh;
    width: auto;
    margin-top: auto;
  } */

  h1 {
    font-size: 29px;
  }

  .tab-wrap {
    width: 95%;
  }

  .grid__85 {
    width: 85%;
  }

  .grid__15 {
    width: 15%;
  }

  .grid__psgform {
    width: 70%;
  }

  .grid__cservice {
    width: 30%;
  }

  .pc-view {
    display: inline-block;
  }

  .mobile-view {
    display: none;
  }

  .left-mobile {
    border-left: none;
  }

  .grid__20,
  .grid__filter,
  .grid__hotel__pricing {
    width: 20%;
  }

  .grid__25 {
    width: 25%;
  }

  .tabs__item {
    padding: 0 30px;
  }

  .grid-description {
    font-size: 1.1em;
  }

  .grid__boxed {
    padding: 1em !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  section,
  footer {
    padding: 70px 15px;
  }

  .tfx-services {
    height: 80vh;
    background-size: cover;
  }

  .flight-result,
  .flight-filter,
  .hotel-result,
  .hotel-room,
  .hotel-filter,
  .package-result,
  .package-room,
  .package-filter,
  .slider-cta {
    margin-top: -80px;
  }

  .tfx-header a.tfx-logo {
    background-size: 90%;
  }

  .form-steps {
    margin-top: auto;
  }

  .login-form {
    margin-top: auto;
  }

  .tfx-user-form-wrapper {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 992px) {
  .services-tab {
    width: 95%;
  }

  .grid__full,
  .grid__hotel__desc,
  .grid__hotel__img,
  .grid__hotel__pricing {
    width: 100%;
  }

  .grid__half,
  .grid__services,
  .hotel-result,
  .hotel-room,
  .package-result,
  .package-room,
  .slider-cta {
    margin-top: -80px;
  }

  .grid__skillset,
  .grid__three {
    width: 33.33%;
  }

  .grid__testimonial {
    width: 31.33%;
  }

  .grid__four,
  .pricing--item {
    width: 25%;
  }

  .grid__five {
    width: 20%;
  }

  .grid__six {
    width: 16.66%;
  }

  .tfx-services {
    height: 80vh;
    background-size: cover;
  }

  .grid__psgform {
    width: 70%;
  }

  .grid__cservice {
    width: 30%;
  }

  .grid__80,
  .grid__results {
    width: 80%;
  }

  .grid__70 {
    width: 70%;
  }

  .grid__75 {
    width: 75%;
  }

  .grid__35 {
    width: 35%;
  }

  .grid__30 {
    width: 30%;
  }

  .grid__65 {
    width: 65%;
  }

  .grid__25 {
    min-height: 20px;
  }

  .grid__60 {
    width: 60%;
  }

  .grid__40 {
    width: 40%;
  }

  h1 {
    font-size: 28px;
  }

  .tab-wrap {
    width: 95%;
  }

  .grid__85 {
    width: 85%;
  }

  .grid__15 {
    width: 15%;
  }

  .tfx-header a.tfx-logo {
    background-size: 100%;
  }

  .pc-view {
    display: inline-block;
  }

  .mobile-view {
    display: none;
  }

  .grid__20,
  .grid__filter {
    width: 20%;
  }

  .grid__25 {
    width: 25%;
  }

  .tabs__item {
    padding: 0 30px;
  }

  .grid__boxed {
    padding: 1em !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  .tfx-header a.tfx-logo {
    margin-left: 0;
    margin-top: 1px;
  }
  /* 
  .tfx-icon {
    height: 100vh;
    width: auto;
    margin-top: auto;
  } */

  section,
  footer {
    padding: 70px 15px;
  }

  .flight-result,
  .flight-filter,
  .hotel-filter,
  .package-filter {
    width: 100%;
  }

  .left-mobile {
    border-left: none;
  }

  .contain-content {
    height: 80vh;
  }

  .center-container {
    height: 93vh;
  }

  .form-steps {
    margin-top: auto;
  }

  .login-form {
    margin-top: auto;
  }

  .tfx-user-form-wrapper {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 768px) {
  .services-tab {
    width: 100%;
  }

  .grid__full,
  .grid__filter,
  .grid__results,
  .grid__hotel__desc,
  .grid__hotel__img,
  .grid__hotel__pricing {
    width: 100%;
  }

  .grid__half,
  .grid__skillset,
  .pricing--item,
  .grid__services,
  .hotel-result,
  .hotel-room,
  .package-result,
  .package-room,
  .slider-cta {
    margin-top: -80px;
  }

  .grid__testimonial {
    width: 48%;
  }

  .grid__25,
  .grid__three {
    width: 50%;
  }

  .grid__four {
    width: 50%;
  }

  .grid__five {
    width: 20%;
  }

  .grid__six {
    width: 16.66%;
  }

  .grid__80 {
    width: 80%;
  }

  .grid__70,
  .grid__75 {
    width: 70%;
  }

  .grid__65 {
    width: 65%;
  }

  .grid__60 {
    width: 60%;
  }

  .grid__40 {
    width: 40%;
  }

  .grid__boxed {
    padding: 1em !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  .grid__35 {
    width: 35%;
  }

  .grid__30 {
    width: 30%;
  }

  .tabs__item {
    padding: 0 10px;
  }

  .grid__20 {
    width: 20%;
  }

  .grid__25 {
    min-height: 20px;
  }

  .grid__85 {
    width: 85%;
  }

  .grid__15 {
    width: 15%;
  }

  .grid__psgform {
    width: 65%;
  }

  .grid__cservice {
    width: 35%;
  }

  .mobile-view {
    display: inline-block;
  }

  .pc-view {
    display: none;
  }

  .left-mobile {
    border-left: none;
  }

  .contain-content {
    height: 87vh;
  }

  .center-container {
    height: 93vh;
  }

  .grid__boxed {
    padding: 1em !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  .tfx-header a.tfx-logo {
    margin-left: 0;
    margin-top: 1px;
  }

  /* .tfx-icon {
    height: 100vh;
    width: auto;
    margin: 0;
  } */

  section,
  footer {
    padding: 70px 10px;
  }

  h1 {
    font-size: 26px;
  }

  .tab-wrap {
    width: 95%;
  }

  .tfx-services {
    height: 80vh;
    background-size: cover;
  }

  .flight-result {
    width: 50%;
  }

  .flight-filter,
  .hotel-filter,
  .package-filter {
    width: 100%;
  }

  .tfx-header a.tfx-logo {
    background-size: 90%;
  }

  .left-mobile {
    border-left: none;
  }

  .form-steps {
    margin-top: auto;
  }

  .login-form {
    margin-top: auto;
  }

  .tfx-user-form-wrapper {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 480px) {
  .services-tab {
    width: 100%;
  }

  .grid__full,
  .grid__half,
  .grid__three,
  .grid__four,
  .grid__skillset,
  .grid__services,
  .grid__five,
  .grid__six,
  .grid__20,
  .grid__filter,
  .grid__25,
  .grid__30,
  .grid__35,
  .grid__40,
  .grid__60,
  .grid__65,
  .grid__70,
  .grid__75,
  .grid__80,
  .grid__results,
  .grid__85,
  .grid__15,
  .pricing--item,
  .grid__hotel__desc,
  .grid__hotel__img,
  .grid__hotel__pricing {
    width: 100%;
  }

  .tfx-services {
    height: 130vh;
    background-size: cover;
  }

  .grid__boxed {
    padding: 1em !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  .grid-description {
    font-size: 1em;
  }

  .tfx-header a.tfx-logo {
    margin-left: 0;
    margin-top: 1px;
  }

  /* .tfx-icon {
    margin-top: 55%;
    height: 100vh;
    width: 100%;
    background: url(../images/advancly-loader.png) no-repeat transparent;
    background-size: contain;
  } */

  section,
  footer {
    padding: 70px 0;
  }

  h1 {
    font-size: 30px;
  }

  .tab-wrap {
    width: 100%;
  }

  .flight-result,
  .flight-filter,
  .hotel-result,
  .hotel-room,
  .hotel-filter,
  .package-result,
  .package-room,
  .package-filter,
  .slider-cta {
    margin-top: 80px;
  }

  .grid__psgform,
  .grid__cservice {
    width: 100%;
  }

  .tfx-header a.tfx-logo {
    background-size: 90%;
    margin-left: 12%;
  }

  .mobile-view {
    display: inline-block;
  }

  .pc-view {
    display: none;
  }

  .left-mobile {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
  }

  .contain-content {
    height: 92vh;
  }

  .center-container {
    height: 93vh;
  }

  .form-steps {
    margin-top: 33%;
  }

  .login-form {
    margin-top: 25%;
  }

  .tfx-user-form-wrapper {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 360px) {
  .services-tab {
    width: 100%;
  }

  .grid__full,
  .grid__half,
  .grid__three,
  .grid__four,
  .grid__skillset,
  .grid__five,
  .grid__six,
  .grid__20,
  .grid__filter,
  .grid__25,
  .grid__30,
  .grid__35,
  .grid__40,
  .grid__60,
  .grid__65,
  .grid__70,
  .grid__75,
  .grid__80,
  .grid__results,
  .pricing--item,
  .grid__85,
  .grid__15,
  .grid__hotel__desc,
  .grid__hotel__img,
  .grid__hotel__pricing {
    width: 100%;
  }

  .tfx-services {
    height: 140vh;
    background-size: cover;
  }

  .grid__testimonial {
    width: 98%;
  }

  .grid__boxed {
    padding: 1em !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  .tfx-header a.tfx-logo {
    margin-left: 0;
    margin-top: 1px;
  }
  /* 
  .tfx-icon {
    margin-top: 55%;
    height: 100vh;
    width: 100%;
    background: url(../images/advancly-loader.png) no-repeat transparent;
    background-size: contain;
  } */

  section,
  footer {
    padding: 70px 0;
  }

  h1 {
    font-size: 39px;
  }

  .tab-wrap {
    width: 100%;
  }

  .flight-result,
  .flight-filter,
  .hotel-result,
  .hotel-room,
  .hotel-filter,
  .package-result,
  .package-room,
  .package-filter,
  .slider-cta {
    margin-top: 80px;
  }

  .grid__psgform,
  .grid__cservice {
    width: 100%;
  }

  .tfx-header a.tfx-logo {
    background-size: 90%;
    margin-left: 4.5%;
  }

  .mobile-view {
    display: inline-block;
  }

  .pc-view {
    display: none;
  }

  .left-mobile {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
  }

  .contain-content {
    height: 92vh;
  }

  .center-container {
    height: 93vh;
  }

  .form-steps {
    margin-top: 33%;
  }

  .login-form {
    margin-top: 26%;
  }

  .tfx-user-form-wrapper {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 320px) {
  .services-tab {
    width: 100%;
  }

  .grid__full,
  .grid__half,
  .grid__three,
  .grid__four,
  .grid__skillset,
  .grid__five,
  .grid__six,
  .grid__20,
  .grid__filter,
  .grid__25,
  .grid__30,
  .grid__35,
  .grid__40,
  .grid__60,
  .grid__65,
  .grid__70,
  .grid__75,
  .grid__80,
  .grid__results,
  .pricing--item,
  .grid__85,
  .grid__15,
  .grid__hotel__desc,
  .grid__hotel__img,
  .grid__hotel__pricing {
    width: 100%;
  }

  .grid__psgform,
  .grid__cservice {
    width: 100%;
  }

  .mobile-view {
    display: inline-block;
  }

  .pc-view {
    display: none;
  }

  .grid__testimonial {
    width: 98%;
  }

  .tfx-header a.tfx-logo {
    margin-left: 4.5%;
    margin-top: 1px;
  }

  /* .tfx-icon {
    margin-top: 50%;
    height: 100vh;
    width: 100%;
    background: url(../images/advancly-loader.png) no-repeat transparent;
    background-size: contain;
  } */

  section,
  footer {
    padding: 70px 0;
  }

  h1 {
    font-size: 27px;
  }

  .tab-wrap {
    width: 100%;
  }

  .tfx-services {
    height: 150vh;
    background-size: cover;
  }

  .flight-result,
  .flight-filter,
  .hotel-result,
  .hotel-room,
  .hotel-filter,
  .package-result,
  .package-room,
  .package-filter,
  .slider-cta {
    margin-top: 80px;
  }

  .tfx-header a.tfx-logo {
    background-size: 90%;
  }

  .left-mobile {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
  }

  .contain-content {
    height: 91vh;
  }

  .center-container {
    height: 93vh;
  }

  .form-steps {
    margin-top: 33%;
  }

  .login-form {
    margin-top: 30%;
  }

  .tfx-user-form-wrapper {
    margin: 0 auto;
  }
}

table td,
table th {
  vertical-align: middle !important;
  font-size: 0.725rem;
  text-align: center;
}
