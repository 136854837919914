.previewContainer {
  height: 80vh;
  display: flex;
  justify-content: center;

  .dropdownButton {
    margin: 10rem auto;
    display: flex !important;
    width: 10rem;
  }

  & > .download {
    display: block;
    height: 3rem;
    width: 10rem;
  }
}
