// @font-face {
//   font-family: font-bold;
//   src: url("../../assets/fonts/Inter/static/Inter-Bold.ttf");
// }
// @font-face {
//   font-family: font-regular;
//   src: url("../../assets/fonts/Inter/static/Inter-Regular.ttf");
// }
// @font-face {
//   font-family: font-medium;
//   src: url("../../assets/fonts/Inter/static/Inter-Medium.ttf");
// }

// @font-face {
//   font-family: font-semi-bold;
//   src: url("../../assets/fonts/Inter/static/Inter-SemiBold.ttf");
// }

// NEW FONTS
@font-face {
  font-family: font-regular;
  src: url("../../assets/fonts/DM_Sans/DMSans-Regular.ttf");
}
@font-face {
  font-family: font-medium;
  src: url("../../assets/fonts/DM_Sans/DMSans-Medium.ttf");
}

@font-face {
  font-family: font-semi-bold;
  src: url("../../assets/fonts/DM_Sans/DMSans-Bold.ttf");
}

:root {
  --colorBlue: #713fff;
  --colorLightBlue: #e3d9ff;
  --colorMidLightBlue: #f8f5ff;
  --lightBlue: #e3f2fc;
  --colorAdvanclyCultured: #f2f5f9;
  --colorMidBlue: #713fff;
  --colorDarkBlue: #200865;
  --colorBackgroundBlue: #200865;
  --colorBackgroundHeaderBlue: #200865;
  --colorDimGrey: #707070;
  --colorMidGrey: #d7dce0;
  --colorLightBlack: #272525;
  --colorLighterGrey: #f4f5f6;
  --colorLightGreen: #00a006;
  --colorLightGrey: #8d8585;
  --colorDimGreen: #27be63;
  --colorYellow: #ec9523;
  --colorOrange: #f08829;
  --colorRed: #ce0000;
  --colorDimRed: #ca1b1b;
  --cardBorderColor: #3a434b;
  --colorGreyPurple: #645e77;
  --AdvanclyColorGrey: #d1cfd6;
  --colorDarkPurple: #16054e;
  --colorDarkIndigo: #150849;
  --colorGrey: #8b8699;

  --colorWhite: #ffffff;
  --white: #ffffff;
  --colorBlack: #000000;
  --borderRadius: 12px;
  --borderRadiusSm: 6px;
  --borderRadiusMd: 9px;
  --borderRadiusLg: 15px;
  --fontRegular: font-regular;
  --fontSemiBold: font-semi-bold;
  --fontBold: font-semi-bold;
  --fontMedium: font-medium;
  --headerHeight: 65px;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}

body {
  line-height: 1.5;
  background-color: var(--colorWhite);
  color: var(--colorBlack);
  font-family: var(--fontRegular) !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100%;
}

::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: var(--colorMidGrey);
}
::-webkit-scrollbar {
  width: 12px;
  height: 8px;
  background-color: var(--colorMidGrey);
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: var(--colorMidBlue);
}

a {
  &:hover {
    text-decoration: none;
  }
}

.text-underline {
  text-decoration: underline;
}

@mixin advancly-btn {
  border-radius: 4px;
  padding: 12px 24px;
  color: var(--colorWhite);
  font-weight: 600;

  &:hover {
    color: var(--colorWhite);
    font-weight: bold;
  }

  &:focus {
    box-shadow: unset;
    outline: 0;
  }
}

.advancly-btn {
  @include advancly-btn();
  background-color: var(--colorMidBlue);
}
.advancly-small-btn {
  border-radius: 4px;
  padding: 6px 12px;
  color: var(--colorWhite);
  font-weight: 500;
  outline: none;

  &:hover {
    background-color: var(--colorBackgroundHeaderBlue);
    font-weight: bold;
  }

  &:focus {
    box-shadow: unset;
    outline: 0;
  }
  background-color: var(--colorMidBlue);
}

.btn-sm {
  padding: 8px 16px !important;
}

.advancly-white-btn {
  @include advancly-btn();
  background-color: var(--colorWhite);
  border-color: var(--colorMidBlue);
  color: var(--colorMidBlue);

  &:hover {
    border-color: var(--colorMidBlue);
    color: var(--colorMidBlue);
    font-weight: bold;
  }
}

.advancly-nobg-btn {
  @include advancly-btn();
  background-color: var(--colorWhite);
  border-color: var(--colorWhite);
  color: var(--cardBorderColor);

  &:hover {
    border-color: var(--colorWhite);
    color: var(--cardBorderColor);
    font-weight: bold;
  }
}

.advancly-off-btn {
  @include advancly-btn();
  background-color: #e8effd;
  border-color: #e8effd;
  color: var(--colorMidBlue);

  &:hover {
    border-color: #e8effd;
    color: var(--colorMidBlue);
    font-weight: bold;
  }
}

.advancly-off-2-btn {
  @include advancly-btn();
  background-color: #f5f6fa;
  border-color: #f5f6fa;
  color: var(--colorMidBlue);

  &:hover {
    border-color: #f5f6fa;
    color: var(--colorMidBlue);
    font-weight: bold;
  }
}

.advancly-grey-btn {
  @include advancly-btn();
  background-color: #81909d;
  border-color: #81909d;
  color: var(--colorWhite);

  &:hover {
    border-color: #81909d;
    color: var(--colorWhite);
    font-weight: bold;
  }
}

.bg-primary,
.btn-primary {
  background-color: #713fff;
}
.advancly-red-btn {
  @include advancly-btn();
  background-color: #fce9e9;
  border-color: #fce9e9;
  color: var(--colorDimRed);

  &:hover {
    border-color: #fce9e9;
    color: var(--colorDimRed);
    font-weight: bold;
  }
}

.advancly-green-btn {
  @include advancly-btn();
  background-color: #32de84;
  border-color: #32de84;
  color: var(--colorWhite);

  &:hover {
    border-color: #fce9e9;
    color: var(--colorWhite);
    font-weight: bold;
  }
}

.advancly-red-decline-btn {
  @include advancly-btn();
  background-color: #fff;
  border-color: #fff;
  color: var(--colorDimRed);

  &:hover {
    color: var(--colorDimRed);
    border-color: var(--colorDimRed);
    font-weight: bold;
  }
}
.advancly-red-approve-btn {
  @include advancly-btn();
  background-color: #fff;
  border-color: #fff;
  color: var(--colorMidBlue);

  &:hover {
    color: var(--colorMidBlue);
    border-color: var(--colorMidBlue);
    font-weight: bold;
  }
}

.advancly-dimred-btn {
  @include advancly-btn();
  background-color: #e43535;
  border-color: #e43535;
  color: var(--colorWhite);

  &:hover {
    border-color: #e43535;
    color: var(--colorWhite);
    font-weight: bold;
  }
}

.input-loading {
  background-color: #ffffff;
  background-image: url("http://loadinggif.com/images/image-selection/3.gif");
  background-size: 25px 25px;
  background-position: center center;
  background-repeat: no-repeat;
}

.extra-info {
  border-color: #c4c4c4;
  color: #c4c4c4;
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid #c4c4c4;
  margin-bottom: 16px;
  border-radius: 4px;
  padding: 8px;
}

.disabled-link {
  pointer-events: none;
  cursor: default !important;
}

.bg-white {
  background-color: var(--colorWhite);
}

.bg-blue {
  background-color: var(--colorBackgroundBlue);
}
.bg-lightblue {
  background-color: var(--lightBlue);
}
.color-card-border-color {
  color: var(--cardBorderColor);
}
.color-white {
  color: var(--colorWhite) !important;
}
.color-blue {
  color: var(--colorBackgroundBlue);
  &:hover {
    color: #713fff;
  }
}

.color-mid-blue {
  color: var(--colorMidBlue);
}
.color-light-blue {
  color: var(--colorBlue);
}
.bg-lighter-grey {
  background-color: var(--colorLighterGrey);
}
.color-black {
  background-color: black;
}
.pending-loan-btn {
  display: flex;
}

.horizontal-line {
  height: 2px;
  position: relative;
  background-color: var(--colorMidGrey);
  margin: 10px 0px;
}

.page-title {
  font-weight: 600;
  font-size: clamp(24px, 2.5vw, 28px);
  line-height: 32px;
  letter-spacing: 0.25px;
  color: var(--colorBackgroundBlue);
  font-family: var(--fontSemiBold);
}
.page-subtitle {
  color: var(--cardBorderColor);
  font-family: var(--fontSemiBold);
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #0d0f11;
}
.cancel-title {
  color: var(--cardBorderColor);
  font-family: var(--fontSemiBold);
  font-size: 16px;
  line-height: 24px;
  color: #3a434b;
}
::selection {
  background-color: #713fff !important;
  color: white;
}

@mixin tags {
  padding: 4px 8px;
  border-radius: 0.25rem;
  text-transform: capitalize;
}

@mixin activeTag {
  @include tags();
  background-color: #fdf2e7;
  color: #f08829;
}
@mixin inactiveTag {
  @include tags();
  color: var(--colorMidBlue);
  background-color: #e8effd;
}
@mixin successTag {
  @include tags();
  color: var(--colorDimGreen);
  background-color: #eafbf1;
}
@mixin pendingTag {
  @include tags();
  color: #5e4bce;
  background-color: #edebfa;
}
@mixin errorTag {
  @include tags();
  color: var(--colorDimRed);
  background-color: #fce9e9;
}
@mixin rejectedTag {
  @include tags();
  color: var(--colorDimRed);
  background-color: #fce9e9;
}

.dashboard-graph-list {
  padding: 0px;
  list-style: none;

  li {
    display: flex;
    padding: 8px 0px;
    margin-bottom: 4px;
    justify-content: space-between;
    flex-wrap: wrap;
    line-height: 16px;
    font-size: 14px;
    border-bottom: 1.5px dashed var(--colorMidGrey);

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:nth-child(1) {
        max-width: 65%;
      }
      &:nth-child(2) {
        max-width: 35%;
        &.active {
          @include activeTag();
        }
        &.inactive {
          @include inactiveTag();
        }
        &.success {
          @include successTag();
        }
        &.repaid {
          @include successTag();
        }
        &.pending {
          @include pendingTag();
        }
        &.error {
          @include errorTag();
        }
        &.rejected {
          @include rejectedTag();
        }
        &.failed {
          @include errorTag();
        }
        &.due {
          @include errorTag();
        }
        &.overdue {
          @include errorTag();
        }
      }
    }
  }
}

td {
  .matured {
    @include successTag();
  }
  .active {
    @include activeTag();
  }
  .inactive {
    @include inactiveTag();
  }
  &.success {
    @include successTag();
  }
  &.repaid {
    @include successTag();
  }
  &.pending {
    @include pendingTag();
  }
  &.error {
    @include errorTag();
  }
  &.rejected {
    @include rejectedTag();
  }
  &.failed {
    @include errorTag();
  }
  &.due {
    @include errorTag();
  }
  &.overdue {
    @include errorTag();
  }
  &.closed {
    @include inactiveTag();
  }
  &.approved {
    @include successTag();
  }
  &.writtenoff {
    @include errorTag();
  }
  &.rescheduled {
    @include pendingTag();
  }
  &.overpaid {
    @include errorTag();
  }
}

span {
  &.declined {
    @include errorTag();
  }
  &.active {
    @include activeTag();
  }
  &.inactive {
    @include inactiveTag();
  }
  &.success {
    @include successTag();
  }
  &.repaid {
    @include successTag();
  }
  &.pending {
    @include pendingTag();
  }
  &.error {
    @include errorTag();
  }
  &.rejected {
    @include rejectedTag();
  }
  &.failed {
    @include errorTag();
  }
  &.due {
    @include errorTag();
  }
  &.overdue {
    @include errorTag();
  }
  &.closed {
    @include inactiveTag();
  }
  &.approved {
    @include successTag();
  }
  &.writtenoff {
    @include errorTag();
  }
  &.rescheduled {
    @include pendingTag();
  }
  &.creditofficer {
    @include inactiveTag();
  }
  &.overpaid {
    @include errorTag();
  }
}

.crimson {
  color: crimson;
}

.form-label {
  font-size: 14px !important;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  text-transform: capitalize !important;
}

.doc-page {
  padding: 12px;
  background: #f4f5f6;
  border-radius: 4px;
  max-height: 70vh;
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #505d67;
  }
}

// for table styling credit bureau
.table-caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--AdvanclyColorGrey);
  font-weight: 500;
}
.table-style {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid var(--AdvanclyColorGrey);
  background-color: #fff;
  text-align: center;

  .th-td-style {
    border: 0.5px solid var(--AdvanclyColorGrey);
    padding: 0.91rem 0.62rem;
    font-weight: normal;
    font-size: 0.875rem;
    color: var(--colorGreyPurple);
  }
  .th-style {
    color: var(--colorDarkPurple);
    font-weight: bold;
  }
}
/*------------------------------------
  Transitions
------------------------------------*/
/* 3D Hover */
.transition-3d-hover {
  transition: all 0.2s ease-in-out;
}

.transition-3d-hover:hover,
.transition-3d-hover:focus {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

/*------------------------------------
  Font Weight
------------------------------------*/
.font-weight-medium {
  font-weight: 500 !important;
  font-family: var(--fontBold);
}

.font-weight-semi-bold {
  font-weight: 600 !important;
  font-family: var(--fontBold);
}

.font-weight-bold {
  font-family: var(--fontBold);
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}

.custom-search-input .search-query {
  padding-right: 3px;
  padding-right: 4px \9;
  padding-left: 16px;
  padding-left: 4px \9;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.custom-search-input span {
  border: 0;
  background: none;
  padding: 2px 16px 2px 0px;
  margin-top: 8px;
  position: relative;
  left: -28px;
  margin-bottom: 0;
  border: 1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: var(--cardBorderColor);
}
.search-query:focus + span {
  z-index: 3;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.alignToggleTabItems {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid var(--colorMidGrey);
  margin-bottom: 1.25rem;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 3rem;

  &.centralize {
    justify-content: center;
  }

  .tabItem {
    text-transform: capitalize;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #0d0f11;
    cursor: pointer;
    text-align: center;
    width: max-content;
    text-wrap: nowrap;
    margin-bottom: 1rem;
    position: relative;

    &.activeTab {
      font-weight: 500;
      color: var(--colorBackgroundBlue);
      font-family: var(--fontBold);

      &::after {
        content: " ";
        width: 100%;
        display: inline-block;
        height: 4px;
        background-color: var(--colorMidBlue);
        position: absolute;
        left: 0rem;
        top: 1.75rem;
      }
    }
  }
}

.more-icon {
  cursor: pointer;
  &::before {
    content: url(../svg/icons/more-icon.svg);
  }
}

.input-group {
  .form-control {
    &:focus {
      box-shadow: 0px 2px 4px rgba(171, 180, 189, 0.23);
      border: 1px solid #ddd;
    }
  }
  .input-group-append {
    .input-group-text {
      background-color: unset;
      color: var(--colorMidBlue);
      font-size: 14px;
      line-height: 16px;

      &:hover {
        font-weight: bold;
        font-family: var(--fontBold);
      }
    }
  }
}

.disabled {
  pointer-events: none;
}

.custom-control-input {
  &:focus {
    ~ .custom-control-label::before {
      box-shadow: unset;
    }
  }
  &:checked ~ .custom-control-label {
    &::before {
      border-color: var(--colorDimGreen);
      background-color: var(--colorDimGreen);
    }
  }
}

.upload-box-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  .upload-box-icon-border {
    img {
      margin-bottom: 20px;
      height: 50px;
      width: auto;
    }

    background-color: var(--colorLighterGrey);
    padding: 80px;
    cursor: pointer;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.onboardingOuterLayout {
  max-width: 80%;
  min-width: 80%;
  margin: 1rem 0%;
  padding-left: 1rem;

  @media (max-width: 768px) {
    max-width: 100%;
    min-width: 100%;
  }
}

.onboardingInnerLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 70%;
  min-width: 70%;
  position: relative;

  .step {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #677684;
  }
  .viewText {
    color: #1354d3;
    font-size: 14px;
    font-weight: 400;
  }
  .viewText:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .dateText {
    color: #81909d;
    font-size: 12px;
    font-weight: 400;
  }
}

.advancly-primary-text {
  color: var(--colorBackgroundBlue) !important;
}
