.ActionBar {
  width: 100%;
  display: flex;
  justify-content: space-around;

  .AggregatorSearch {
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 540px) {
  .ActionBar {
    flex-direction: column;

    .AggregatorSearch {
      display: flex;
      justify-content: center;
    }
  }
}
