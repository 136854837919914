.close_equity_contribution_btn {

  &:hover {
    color: #713fff !important;
    border-color: #713fff !important;
  }
}

.sweep_equity_contribution_btn {
  background-color: #713fff !important;
  color: #fff !important;
  border-color: #713fff !important;

  &:hover {
    background-color: transparent !important;
    color: #713fff !important;
    border-color: #713fff !important;
  }
}
