.bank_statement_upload_container {
    width: 100%;
    margin-top: 40px;

    .bank_statement_upload_header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-weight: 600;
            font-size: 20px;
        }
    }

    .doc_upload_container {
        width: 50%;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 130dvh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1100;
}

.modal_container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100dvh;
    width: 90%;
    background-color: #FFF;
    border-radius: 20px;
    border-top: 4px solid #CFBEFF;
    box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.10);
    padding: 20px;
    position: fixed;
    overflow-y: scroll;
    z-index: 1200;

    @media (min-width: 1280px) {
        width: 100%;
    }
}
