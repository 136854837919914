.pending {
  color: orangered;
}

.accepted {
  color: #1fa765;
}

.rejected {
  color: #000000;
}

.view_offer_btn {

  &:hover {
    color: #713fff !important;
    border-color: #713fff !important;
  }
}


