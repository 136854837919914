.declinedOfferLetterModalContent {
  //   width: 100vw;
  overflow: auto;
  margin-left: "-10rem";
  margin-top: 5rem;
  @media (max-width: 850px) {
    margin-left: "3rem";
  }
}

.modal_title {
  color: #200865;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 20px;
  margin-bottom: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid rgb(218, 218, 218);
    padding: 5px;
    text-align: left;
  }
  th {
    color: #170d33;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    font-size: 14px;
  }
  td {
    color: #645e77;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    font-size: 14px;

    &:last-of-type {
      text-align: center;

      .icon_button {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    &:nth-of-type(3) {
      text-align: center;

      .pendingStatus {
        border-radius: 40px;
        color: #f5a623;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        background: #fef4e5;
        padding: 2px 10px;
      }
    }
  }

  .decline_button {
    position: relative;
    cursor: pointer;
    color: #713fff;
    text-decoration: underline;

    &:hover {
      color: #4d2da2;
    }

    ._btn {
      position: absolute;
      top: -90px;
      right: -10px;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      width: 153px;
      // height: 116px;
      background-color: #fff;
      padding: 5px;
      border-radius: 10px;
      box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);

      button {
        display: inline-block;
        width: 100%;
        border: none;
        background-color: transparent;
        color: #001540;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        padding: 5px 0;

        &:hover {
          background-color: rgb(246, 246, 246);
        }
      }
    }
  }
}

.DownloadIcon {
  margin-left: 1rem;
}
