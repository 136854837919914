.commentsAction {
  display: flex;
  //   margin: auto;
  //   margin-left: 2rem;
  padding-top: 2.6rem;
  padding-left: 0;
  cursor: pointer;

  & .messageIcon {
    margin-top: 0.23rem;
  }

  & .commentText {
    display: inline-block;
    margin-left: 0.5rem;
    color: var(--colorBlue);
  }
}
