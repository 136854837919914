.BackButton {
  display: flex;
  margin: 0;
  padding: 0;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  line-height: 28px;
  color: #200865;

  svg {
    height: 1rem;
    width: 1rem;
    color: #200865;
    fill: #200865 !important;
    margin-top: 0.4rem;
    margin-right: 2rem;
  }
}

.ActionContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
