.CustomAccordionContainer {
  width: 100%;
  margin-bottom: 1rem;

  .Trigger {
    height: 3rem;
    padding: 0.8rem 1rem;
    border-radius: 10px;
    background-color: #f2f5f9;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;

    span {
      font-size: 0.75rem;
      display: inline-block;
      border-radius: 5px;
      padding: 0 0.625rem;

      &.Approved {
        color: #27be63;
        border: 1px solid #27be63;
        background-color: #eafbf1;
      }

      &.Rejected {
        color: #ff193b;
        border: 1px solid #ff193b;
        background-color: #fce9e9;
      }
    }

    img {
      cursor: pointer;
    }
  }

  .DropDownContainer {
    padding: 0.5rem 2rem;

    .VerificationStates {
      margin-top: 2rem;
      .Title {
        display: inline-block;
        padding: 0.5rem;
        width: 40rem;
        background-color: #f8f8f8;
      }

      ul {
        margin-top: 1rem;
        width: 30rem;

        li {
          margin-bottom: 1rem;
        }

        strong {
          color: #150849;
          font-weight: 700;

          &.Passed {
            color: #27be63;
          }

          &.Failed {
            color: #ff193b;
          }
        }
      }
    }

    .GeneralReason {
      .Title {
        display: inline-block;
        padding: 0.5rem;
        width: 40rem;
        background-color: #f8f8f8;
      }

      ul {
        margin-top: 1rem;
        width: 30rem;

        li {
          margin-bottom: 1rem;
        }

        strong {
          color: #150849;
          font-weight: 700;

          &.Passed {
            color: #27be63;
          }

          &.Failed {
            color: #ff193b;
          }
        }
      }
    }
  }
}
