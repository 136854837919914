.FeedBackBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;

  .Title {
    margin-top: 1.5rem;
    color: #24292e;
    font-weight: 600;
    margin-bottom: 3rem;
    text-align: center;
  }

  .DoneButtonContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    .DoneButton {
      width: 15rem;
    }
  }
}
