input.form-control[type="date"]{
    float: none;
    height: inherit;
    border: 1px solid rgba(0, 0, 0, .19);
}

.Form__action-buttons{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

    
