.modal_title {
    color: #200865;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 20px;
    margin-bottom: 20px;
}

.files_container {
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 40px;
    .file_container {
        display: inline-flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
        .display_icon_doc_name {
            display: flex;
            align-items: center;
            gap: 5px;
            border: 1px solid rgb(203, 201, 201);
            padding: 10px 15px;
            border-radius: 10px;
            color: #713FFF;
            font-size: 14px;
        }

        .cancel_button {
            position: absolute;
            right: -40px;
            top: 10px;
            font-weight: 700;
            box-shadow: 0 0 2px;
            border-radius: 50%;
            height: 23px;
            width: 23px;
            text-align: center;
        }
    }

}

.table {
    width: 100%;
    border-collapse: collapse;

    th, td {
        border: 1px solid rgb(218, 218, 218);
        padding: 8px;
        text-align: left;
    }
    th {
        color:#170D33;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        font-size: 14px;
    }

    .offer_doc {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .view_offer_btn {
            border: none;
            background-color: transparent;
            color: #713FFF;

            &:hover {
                color: black;
            }
        }
    }
}
