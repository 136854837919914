.ManageUsers .dataTables_wrapper table th, .ManageUsers .dataTables_wrapper table td {
    text-align: left;
}

.sm-radius{
    border-radius: 10px !important;
}

.AggregatorCard .adv-clients{
    margin: 0 auto !important;
}

.AggregatorCard .biz_name{
    font-weight: 600;
    margin-bottom: 0.2rem !important;
}

.AggregatorCard .icon{
    margin: 0.2rem !important;
}