.Header {
    margin-bottom: 3rem;
}

.TableResponsive, .Table {
    border: none !important;

    .TableCell {
        .NotificationTitle {
            font-weight: 600;
            color: #0D0F11;
        }

        .NotificationMessage {
            color: #505D67;
        }
    }
}

.ActionContainer {
    display: flex;

    .FilterOptions {
       display: flex;

       .DateGroup {
        display: flex;
        
        .DateLabel {
            color: #677684;
            margin-right: 0.5rem;
        }

        .FilterInput {
            border: 1px solid #D7DCE0;
            width: 8rem;
            height: 2.6rem;
            border-radius: 4px;
            padding: 0 1rem;
            color: #677684;
            font-size: 0.875rem;
            margin-right: 1rem;
        }

        .DateInput {
            width: 10rem;
        }
       }

        .FormGroup {
            position: relative;

            .SearchInput {
                border: 1px solid #D7DCE0;
                width: 18rem;
                height: 2.6rem;
                border-radius: 4px;
                padding: 0 1rem;
                color: #677684;
                margin-right: 1rem;
            }

            .FilterInput {
                border: 1px solid #D7DCE0;
                width: 8rem;
                height: 2.6rem;
                border-radius: 4px;
                padding: 0 1rem;
                color: #677684;
                font-size: 0.875rem;
                margin-right: 1rem;
            }

            .FilterMessageType {
                width: 10rem;
            }
    
            .Icon {
                position: absolute;
                right: 2rem;
                top: 0.8rem;
                color: #677684;
            }
        }  
    }

    .FormButtonContainer {
        .SearchActionButton {
            height: 2.6rem;
        }
    }
}

.SendMessageContainer {
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-end;

    .SendNewMessage {
        margin: 0 !important;
    }
}


@media only screen and (max-width: 930px) {
    .ActionContainer {
        flex-direction: column;

        .FormButtonContainer {
            display: flex;
            justify-content: center;
        }
    }
}

@media only screen and (max-width: 800px) {
    .ActionContainer {

        .FilterOptions {
            display: flex;
            flex-direction: column;

            .FormGroup {
                .SearchInput, .FilterInput, .FilterMessageType {
                    width: 100%;
                }
        
                .Icon {
                    position: absolute;
                    right: 2rem;
                    top: 0.8rem;
                    color: #677684;
                }
            }

            .DateGroup {
                display: flex;
                flex-direction: column;

                .FilterInput {
                    width: 100%
                }
            }
        }
    }
}