.Modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  outline: 0;
  /* overflow: hidden; */
  overflow-y: scroll;
  max-height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

/* .Modal.fade .Modal__dialog{
    transition: transform .3s ease-out;
    transform: translateY(-25%);
} */

.Modal .Modal__dialog {
  margin-top: 40px;
}

.Modal__dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.Modal__dialog.lg-modal {
  max-width: 850px !important;
  min-width: 850px !important;
}

.Modal__dialog.xlg-modal {
  max-width: 1000px !important;
  min-width: 1200px !important;
}

.Modal__dialog.md-modal {
  max-width: 550px;
  min-width: 550px;
}

.Modal__dialog.md-lg-modal {
  max-width: 700px;
  min-width: 700px;
}

.Modal__dialog .Modal__content {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  border: none;
}

.Modal__content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
}

.Modal__dialog .Modal__header {
  border-bottom: none;
  padding: 24px 24px 0;
}

.Modal__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.Modal__dialog .Modal__body {
  padding: 24px 24px 16px;
}

.Modal__body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.Modal__dialog .Modal__body + .Modal__footer {
  padding-top: 0;
}

.Modal__dialog .Modal__footer {
  border-top: none;
  padding: 24px;
}

.Modal .close {
  position: absolute;
  padding: 0;
  border-radius: 50%;
  top: 20px;
  right: 20px;
  border: none;
  color: #713fff;
  opacity: 1;
  text-shadow: none;
}

.Modal__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.Modal.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.Modal.fade.show {
  opacity: 1;
}

.Modal.show .Modal__dialog {
  transform: translate(0);
}

@media (min-width: 576px) {
  .Modal__dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .Modal__dialog.lg-modal {
    max-width: 500px;
  }

  .Modal__content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
}
