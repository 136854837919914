.uploadedDocCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--colorWhite);
  border: 1px solid var(--colorMidGrey);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  width: 100%;

  .viewDocument {
    font-size: 0.85rem;
    color: var(--colorBlue);
    font-weight: 700;
    margin-top: 0.2rem;
    margin-right: 1rem;
    margin-left: 0.1rem;
    cursor: pointer;
  }

  .left {
    max-width: 80%;
    min-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
      margin-right: 1rem;
    }
  }

  .right {
    cursor: pointer;
  }
}
