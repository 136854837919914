//view comments
.commentsContainer {
  border-radius: 0rem 0rem 0.625rem 0.625rem;
  background: #f2f5f9;
  padding: 0.75rem 0.9375rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  margin-top: 1rem;
  margin-bottom: 1rem;
}
