.borrower_details_container {
  width: 100%;
  margin-top: 30px;

  @media (min-width: 1280px) {
    margin-top: 0;
  }

  .borrower_details_header {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: column-reverse;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 1280px) {
      flex-direction: row;
      justify-content: space-between;
    }

    .header_title {
      color: #200865;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.2px;

      @media (min-width: 1280px) {
        font-size: 24px;
        letter-spacing: 0.2px;
        line-height: 24px;
      }
    }

    .download_cam_btn {
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: #713fff;
      border: none;
      color: #fff;
      border-radius: 5px;
      font-size: 14px;
      padding: 8.5px 8px;
      // margin-right: 8px;

      @media (max-width: 400px) {
        padding: 7px 8px;
      }

      &:hover {
        background-color: #7b4ef7;
      }

      @media (min-width: 940px) {
        padding: 10px 15px;
        // margin-right: 16px;
      }
    }

    .generate_btn_small {
      background-color: #fff;
      color: #713fff;
      font-size: 14px;
      padding: 6px 6px;
      border-radius: 5px;
      border: 1px solid #713fff;

      @media (min-width: 940px) {
        display: none;
      }

      &:hover {
        background-color: #7b4ef7;
        color: #fff;
      }
    }

    .hide_btn {
      display: none;

      @media (min-width: 940px) {
        display: block;
      }
    }
  }

  .doc_upload_container {
    width: 100%;
    margin-top: 30px;

    .doc_type_selector {
      margin-bottom: 24px;

      .dropdown {
      }
    }

    .upload_box {
    }

    @media (min-width: 1280px) {
      width: 464px;
    }
  }

  .offer_letter_status {
    text-align: right;
    margin-bottom: 30px;

    .status_container {
      display: inline-block;
      margin-left: 20px;
    }
    .document_not_uploaded {
      color: #713fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      border-radius: 40px;
      background: #e9e2fb;
      padding: 4px 8px;
    }

    .customer_accepted {
      color: #f5a623;
      background: #fef4e5;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      border-radius: 40px;
      padding: 4px 8px;
    }
    .advancly_accepted {
      color: #25ad25;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      border-radius: 40px;
      background: #eaf9ea;
      padding: 4px 8px;
    }
  }

  .doc_table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid rgb(218, 218, 218);
      padding: 8px;
      text-align: left;
    }
    th {
      color: #170d33;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      font-size: 14px;
    }

    .offer_doc {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
    }

    .view_offer_btn {
      border: none;
      background-color: transparent;
      color: #713fff;

      &:hover {
        color: black;
      }
    }

    td {
      &:last-of-type {
        // text-align: end;
        // display: flex;
        // justify-content: end;
      }

      &:nth-of-type(5) {
        // text-align: center;

        .acceptedStatus {
          border-radius: 40px;
          color: #25ad25;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          background: #eaf9ea;
          padding: 2px 10px;
        }
      }
    }

    .action_btns {
      display: flex;
      gap: 10px;
    }

    .table_upload_btn {
      border: 1px solid #713fff;
      background-color: transparent;
      border-radius: 5px;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .send_to_customer_btn {
    display: inline-block;
    margin-top: 40px;
  }
}

.prepareOffer {
  background-color: transparent;
  border: none;
  color: #713fff;

  &:hover {
    color: #200865;
  }
}

.DownloadIcon {
  margin-left: 1rem;
}

.ActionBar {
  width: 100%;
  display: flex;
  justify-content: space-around;

  .AggregatorSearch {
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 540px) {
  .ActionBar {
    width: 100%;
    flex-direction: column;
    margin: 0 auto;

    .AggregatorSearch {
      display: flex;
      justify-content: center;
    }
  }
}

.backButton {
  margin: 0;
  padding: 0;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  height: clamp(20px, 2.5vw, 24px);
  width: clamp(20px, 2.5vw, 24px);
  line-height: 28px;
  color: #200865;

  svg {
    height: 100%;
    width: 100%;
    color: #200865;
    fill: #200865 !important;
  }
}
