.commentsAction {
  display: flex;
  //   margin: auto;
  //   margin-left: 2rem;
  padding-top: 2.6rem;
  padding-left: 0;
  cursor: pointer;

  & .messageIcon {
    margin-top: 0.23rem;
  }

  & .commentText {
    display: inline-block;
    margin-left: 0.5rem;
    color: var(--colorBlue);
  }
}

.BackButton {
  display: flex;
  margin: 0;
  padding: 0;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  line-height: 28px;
  color: #200865;

  svg {
    height: 1rem;
    width: 1rem;
    color: #200865;
    fill: #200865 !important;
    margin-top: 0.4rem;
    margin-right: 2rem;
  }
}

.BusinessInfoCard {
  margin-bottom: 2rem;
  padding: 1rem;
  border-radius: 0.625rem;
  border: 0.5px solid #d1cfd6;
}
