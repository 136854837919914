.ActionBar {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .filterContainer {
    display: flex;
    gap: 2rem;

    .filter {
      width: 15rem;
    }
  }
}

@media only screen and (max-width: 1065px) {
  .ActionBar {
    display: block;

    .AggregatorSearch {
      display: flex;
      justify-content: center;
    }
  }
}
