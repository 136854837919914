.rollverDurationContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;

  .durationInterest {
    border: 1px solid var(--colorMidGrey);
    border-radius: 4px 4px 0px 0px;
    height: 7.5rem;
    cursor: pointer;

    .durationContainer {
      height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .duration {
        .durationInNumbers {
          font-size: 1.5rem;
          text-align: center;
          display: block;
        }
      }
    }

    .interest {
      height: 2.5rem;
      background-color: var(--colorMidGrey);
      border: 1px solid var(--colorMidGrey);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 800;
      font-size: 1rem;
    }
  }

  .durationInterest.active {
    border: 1px solid var(--colorBlue);

    .durationContainer {
      .duration {
        .durationInNumbers,
        .months {
          color: var(--colorBlue);
        }
      }
    }

    .interest {
      background-color: var(--colorBlue);
      border: 1px solid var(--colorBlue);
      color: #fff;
    }
  }
}

.result {
  margin-top: 1rem;
  padding-bottom: 1.5rem;

  .estimatedReturns {
    margin-bottom: 1rem;
  }

  .estimatedReturns,
  .monthlyRepayments {
    display: flex;
    justify-content: space-between;

    .title {
      color: #3a434b;
      font-size: 0.875rem;
    }

    .value {
      font-weight: bolder;
    }
  }
}

.repaymentPlanContainer {
  padding-top: 1em;
  border-bottom: 1px solid var(--colorMidGrey);

  p {
    color: #3a434b;
    font-size: 1rem;
  }

  .repaymentPlanActionContainer {
    .action {
      border-radius: 4px;
      background-color: #fff;
      margin-bottom: 1rem;
      transition: border 0.3s ease-in, background-color 0.3s ease-in;

      label {
        color: var(--cardBorderColor);
      }

      .repaymentTypeText {
        padding-left: 1.8rem;
        color: #3a434b;
        font-size: 0.875rem;
      }

      .principalRadio {
        margin-right: 1rem;
        accent-color: var(--colorBlue);
        cursor: pointer;
      }
    }
  }
}

.rolloverInvestmentActionContainer {
  display: flex;

  .header {
    font-size: 1rem;
  }
}

.toolTipAction {
  font-size: 0.75rem;
  color: var(--colorBlue);
  font-style: italic;
}

.finalSection {
  display: flex;
  justify-content: flex-end;

  .previous {
    display: inline-block;
    margin-top: 0.5rem;
    margin-right: 1rem;
    font-weight: bolder;
    cursor: pointer;
  }
}
