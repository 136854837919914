.ActionBar {
  width: 100%;
  display: flex;
  justify-content: space-around;

  .AggregatorSearch {
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 540px) {
  .ActionBar {
   width: 100%;
    flex-direction: column;
    margin: 0 auto;

    .AggregatorSearch {
      display: flex;
      justify-content: center;
    }
  }
}