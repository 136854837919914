.uploadedDocsContainer {
  .documentTimeStamp {
    display: inline-block;
    color: #b2aebb;
    font-size: 0.875rem;
    width: 10rem;
    margin: 1.6rem 1.5rem;
  }
  .commentsAction {
    display: flex;
    margin: auto;
    margin-left: 2rem;
    cursor: pointer;

    & .messageIcon {
      margin-top: 0.23rem;
    }

    & .commentText {
      display: inline-block;
      margin-left: 0.5rem;
      color: var(--colorBlue);
    }
  }

  .downloadUploadDocContainer {
    width: 20rem;
  }
}
